import axios from "axios";

const API_URL = "https://cardfile.geryon.space/api";

export interface ApiDocument {
  name: string;
  lastModified: string;
  path: string;
  url: string;
}

const getAuthToken = () => {
  return localStorage.getItem("access_token");
};

interface FetchDocumentsParams {
  organizationId: number;
  search?: string;
  startDate?: string;
  endDate?: string;
}

export const fetchDocuments = async ({
  organizationId,
  search,
  startDate,
  endDate,
}: FetchDocumentsParams): Promise<ApiDocument[]> => {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error("No token found");
    }

    const params: Record<string, string> = {};
    if (search) params.search = search;
    if (startDate) params.start_date = startDate;
    if (endDate) params.end_date = endDate;

    const response = await axios.get<ApiDocument[]>(
      `${API_URL}/documents/organization/${organizationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении документов", error);
    throw error;
  }
};

export const downloadDocument = async (path: string): Promise<void> => {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error("No token found");
    }

    const response = await axios.get(`${API_URL}/documents/download`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        path,
      },
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", path.split("/").pop()!);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  } catch (error) {
    console.error("Ошибка при скачивании документа", error);
    throw error;
  }
};
