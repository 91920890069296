import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  fetchProjects,
  createProject,
  updateProject,
  deleteProject,
  Project,
  fetchUsers,
} from "../api/projectTasks/api";
import UniversalForm from "../components/UniversalForm/UniversalForm";
import Pagination from "../components/Pagination/Pagination";
import icon_delete from "../images/icons/icon_delete.svg";
import icon_delete_active from "../images/icons/icon_delete_hover.svg";
import icon_all from "../images/icons/icon_all.svg";
import icon_all_active from "../images/icons/all_icon_hover.svg";
import icon_plus from "../images/icons/icon_plus.svg";
import icon_plus_active from "../images/icons/icon_plus_hover.svg";
import icon_edit from "../images/icons/icon_edit.svg";
import icon_edit_active from "../images/icons/icon_edit_hover.svg";
import icon_search from "../images/icons/icon_search.svg";
import icon_search_active from "../images/icons/icon_search_hover.svg";
import AdminBreadcrumbs from "../components/AdminBreadcrumbs/AdminBreadcrumbs";
import FieldSelector from "../components/FieldSelector/FieldSelector";
import SelectStage from "../components/SelectStage/SelectStage";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";

import "./styles/Table.css";
import { User } from "../api/userTasks/userApi";
import { RiseLoader } from "react-spinners";
import { useLogout } from "../helpers/hooks/useLogout";
import { useDebounce } from "../helpers/hooks/useDebounce";

const ProjectFields = [
  {
    name: "id",
    label: "ID",
    placeholder: "ID",
    type: "number",
  },
  {
    name: "title",
    label: "Наименование проекта",
    placeholder: "Наименование проекта",
    type: "text",
    required: true,
  },
  {
    name: "customer",
    label: "Заказчик",
    placeholder: "Заказчик",
    type: "text",
    required: true,
  },
  {
    name: "contact_person",
    label: "Контактное лицо",
    placeholder: "Контактное лицо",
    type: "text",
    required: true,
  },
  {
    name: "contact_data",
    label: "Контактные данные",
    placeholder: "Контактные данные",
    type: "text",
    required: true,
  },
  {
    name: "description",
    label: "Описание проекта",
    placeholder: "Описание проекта",
    type: "text",
    required: true,
  },
  {
    name: "technical_task",
    label: "Техническое задание",
    placeholder: "Техническое задание",
    type: "text",
    required: true,
  },
  {
    name: "status",
    label: "Статус",
    placeholder: "Выберите статус",
    type: "select",
    options: [
      { value: "COMPLETED", label: "Завершен" },
      { value: "INPROGRESS", label: "В процессе" },
      { value: "NOTSTARTED", label: "Не начат" },
      { value: "CHECK", label: "Проверка" },
      { value: "CANCEL", label: "Отмена" },
    ],
  },
  {
    name: "users",
    label: "Участники",
    placeholder: "Участники",
    type: "text",
    options: [],
  },

  {
    name: "start_date",
    label: "Начало",
    placeholder: "Начало",
    type: "text",
  },
  {
    name: "end_date",
    label: "Конец",
    placeholder: "Конец",
    type: "text",
  },
];

const statusTranslations: { [key: string]: string } = {
  COMPLETED: "Завершен",
  INPROGRESS: "В процессе",
  NOTSTARTED: "Не начат",
  CHECK: "Проверка",
  CANCEL: "Отмена",
};

const statusColors: { [key: string]: string } = {
  COMPLETED: "#8beba0",
  INPROGRESS: "#de8beb",
  NOTSTARTED: "#8bc8eb",
  CHECK: "#ebdc8b",
  CANCEL: "#EB9C8B",
};

const stageOptions = [
  { value: "ALL", label: "Все" },
  { value: "ACTIVE", label: "Активные" },
  { value: "FINISHED", label: "Законченные" },
  { value: "POTENCIAL", label: "Потенциальные" },
];

const ProjectTablePage: React.FC = () => {
  useLogout();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shouldAdd = searchParams.get("add") === "true";
  const isEditing = searchParams.get("edit") === "true";

  const [activeAllIcon, setActiveAllIcon] = useState(icon_all);
  const [activePlusIcon, setActivePlusIcon] = useState(icon_plus);
  const [activeSearchIcon, setActiveSearchIcon] = useState(icon_search);
  const [activeDeleteIcon, setActiveDeleteIcon] = useState(icon_delete);
  const [activeIdEditIcon, setActiveIdEditIcon] = useState(0);

  const [projects, setProjects] = useState<Project[]>([]);
  const [isModalOpen, setModalOpen] = useState(shouldAdd);
  const [currentProject, setCurrentProject] = useState<Project | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const sumPage = 4;
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [searchText, setSearchText] = useState<string>("");
  const [breadcrumbs, setBreadcrumbs] = useState<
    { name: string; path: string }[]
  >([]);
  const debouncedSearchText = useDebounce(searchText);

  const [visibleFields, setVisibleFields] = useState<string[]>(
    ProjectFields.map((field) => field.name)
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedStage, setSelectedStage] = useState<string>("ALL");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const fetchedProjects = await fetchProjects(
          selectedStage,
          debouncedSearchText,
          sortOrder === "desc" ? (sortField ?? undefined) : undefined
        );

        console.log("Фильтрация по стадии:", selectedStage);
        console.log("Проекты после фильтрации:", fetchedProjects);

        setProjects(fetchedProjects);
      } catch (err) {
        console.error("Ошибка при загрузке проектов:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedStage, sortOrder, sortField, debouncedSearchText]);

  useEffect(() => {
    setModalOpen(shouldAdd);
  }, [shouldAdd]);

  useEffect(() => {
    if (currentProject) {
      setBreadcrumbs([
        { name: "Панель администратора", path: "/main-admin-panel" },
        { name: "Проекты", path: "/project" },
        { name: currentProject.title || "", path: "" },
      ]);
    } else if (shouldAdd) {
      setBreadcrumbs([
        { name: "Панель администратора", path: "/main-admin-panel" },
        { name: "Проекты", path: "/project" },
        { name: "", path: "" },
      ]);
    } else {
      setBreadcrumbs([
        { name: "Панель администратора", path: "/main-admin-panel" },
        { name: "Проекты", path: "/project" },
      ]);
    }
  }, [currentProject, shouldAdd]);

  const handleOpenModal = (project: Project | null) => {
    setCurrentProject(project);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentProject(null);
  };

  const handleSubmit = async (projectData: Partial<Project>) => {
    try {
      console.log("Данные проекта для отправки:", projectData);
      if (!projectData.status) {
        projectData.status = "COMPLETED";
      }

      if (projectData.users && projectData.users.length > 0) {
        projectData.users = projectData.users.map((user) =>
          typeof user === "number" ? user : user.id
        );
      }

      if (currentProject) {
        const updatedProject = await updateProject(
          currentProject.id!,
          projectData as Partial<Project> & { users?: number[] }
        );
        setProjects((prevProjects) =>
          prevProjects.map((p) =>
            p.id === currentProject.id ? updatedProject : p
          )
        );
      } else {
        const newProject = await createProject(projectData as Project);
        setProjects((prevProjects) => [...prevProjects, newProject]);
      }
      handleCloseModal();
    } catch (err) {
      console.error("Ошибка при создании/обновлении проекта:", err);
    }
  };

  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);

  const handleCheckboxChange = (id: number) => {
    setSelectedProjects((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((i) => i !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setSelectedProjects(checked ? projects.map((project) => project.id!) : []);
  };

  const handleBulkDelete = async () => {
    try {
      for (const id of selectedProjects) {
        await deleteProject(id);
      }
      setProjects((prevProjects) =>
        prevProjects.filter((p) => !selectedProjects.includes(p.id!))
      );
      setSelectedProjects([]);
    } catch (err) {
      console.error("Ошибка при удалении проектов:", err);
    }
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleSort = (fieldName: string) => {
    if (sortField === fieldName) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortField(fieldName);
      setSortOrder("asc");
    }
  };
  const sortedProjects = projects.slice().sort((a, b) => {
    if (sortField) {
      const aValue = a[sortField as keyof Project] as unknown as string;
      const bValue = b[sortField as keyof Project] as unknown as string;

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return sortOrder === "asc"
          ? aValue > bValue
            ? 1
            : -1
          : aValue < bValue
            ? 1
            : -1;
      }
    }
    return 0;
  });

  const lastItem = currentPage * sumPage;
  const firstItem = lastItem - sumPage;
  const currentItems = sortedProjects.slice(firstItem, lastItem);

  const [expandedCells, setExpandedCells] = useState<Set<string>>(new Set());

  const handleCellClick = (id: number, fieldName: string) => {
    const cellKey = `${id}_${fieldName}`;
    setExpandedCells((prevExpandedCells: Set<string>) => {
      const newExpandedCells = new Set(prevExpandedCells);
      if (newExpandedCells.has(cellKey)) {
        newExpandedCells.delete(cellKey);
      } else {
        newExpandedCells.add(cellKey);
      }
      return newExpandedCells;
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const handleToggleField = (fieldName: string) => {
    setVisibleFields((prevFields) =>
      prevFields.includes(fieldName)
        ? prevFields.filter((field) => field !== fieldName)
        : [...prevFields, fieldName]
    );
  };
  const handleStageChange = (value: string) => {
    setSelectedStage(value);
    setCurrentPage(1);
  };

  return (
    <div className="interns-table-page">
      <AdminBreadcrumbs breadcrumbs={breadcrumbs} />
      <div className="control-panel">
        <p className="control-panel_title">Проекты</p>
        {!isModalOpen && (
          <>
            <div className="control-panel_stage">
              <SelectStage
                options={stageOptions}
                selectedValue={selectedStage}
                onChange={handleStageChange}
              />
            </div>
            <div className="left_container">
              <FieldSelector
                fields={ProjectFields}
                visibleFields={visibleFields}
                onToggleField={handleToggleField}
              />
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Найти"
                  value={searchText}
                  onChange={handleSearchChange}
                  className="search-input"
                />
                <img
                  src={activeSearchIcon}
                  alt="Искать"
                  className="search-icon"
                  onMouseEnter={() => {
                    setActiveSearchIcon(icon_search_active);
                  }}
                  onMouseLeave={() => {
                    setActiveSearchIcon(icon_search);
                  }}
                />
              </div>
            </div>
          </>
        )}
        {!currentProject && (
          <div
            className={`addButton_position ${isModalOpen ? "addButton_largeOffset" : ""}`}
          >
            <div className="addButton_position_content">
              <button
                className={isModalOpen ? "inactive" : "active"}
                onClick={handleCloseModal}
                onMouseEnter={() => {
                  setActiveAllIcon(icon_all_active);
                }}
                onMouseLeave={() => {
                  setActiveAllIcon(icon_all);
                }}
              >
                <img src={activeAllIcon} alt="Все" />
                Все
              </button>
              <button
                className={isModalOpen ? "active" : "inactive"}
                onClick={() => handleOpenModal(null)}
                onMouseEnter={() => {
                  setActivePlusIcon(icon_plus_active);
                }}
                onMouseLeave={() => {
                  setActivePlusIcon(icon_plus);
                }}
              >
                <img src={activePlusIcon} alt="Добавить" />
                Добавить
              </button>
            </div>
          </div>
        )}
      </div>
      {isModalOpen ? (
        <div className="modal-background">
          <div className="modal-content">
            <UniversalForm
              item={currentProject}
              onSubmit={handleSubmit}
              fields={ProjectFields}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="all_delete">
            <button
              className="deleteButton"
              onClick={handleBulkDelete}
              disabled={selectedProjects.length === 0}
              onMouseEnter={() => {
                selectedProjects.length > 0 &&
                  setActiveDeleteIcon(icon_delete_active);
              }}
              onMouseLeave={() => {
                selectedProjects.length > 0 && setActiveDeleteIcon(icon_delete);
              }}
            >
              <img src={activeDeleteIcon} alt="Удалить" />
            </button>
          </div>
          {isLoading ? (
            <div
              style={{
                height: 400,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RiseLoader loading={isLoading} size={40} color="red" />
            </div>
          ) : (
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <th className="action_delete">
                      <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={
                          selectedProjects.length === projects.length &&
                          projects.length > 0
                        }
                      />
                    </th>
                    <th className="action"></th>
                    {visibleFields.includes("id") && (
                      <th
                        key="id"
                        onClick={() => handleSort("id")}
                        className={`sortable ${
                          sortField === "id"
                            ? sortOrder === "asc"
                              ? "asc"
                              : "desc"
                            : ""
                        }`}
                      >
                        <span className="header-label">
                          ID
                          <span className="sort-icons">
                            {sortField === "id" && sortOrder === "asc" && (
                              <BsChevronUp className="sort-icon active" />
                            )}
                            {sortField === "id" && sortOrder === "desc" && (
                              <BsChevronDown className="sort-icon active" />
                            )}
                            {!(sortField === "id") && (
                              <BsChevronDown className="sort-icon default" />
                            )}
                          </span>
                        </span>
                      </th>
                    )}
                    {visibleFields.includes("title") && (
                      <th
                        key="title"
                        onClick={() => handleSort("title")}
                        className={`sortable ${
                          sortField === "title"
                            ? sortOrder === "asc"
                              ? "asc"
                              : "desc"
                            : ""
                        }`}
                      >
                        <span className="header-label">
                          Наименование проекта
                          <span className="sort-icons">
                            {sortField === "title" && sortOrder === "asc" && (
                              <BsChevronUp className="sort-icon active" />
                            )}
                            {sortField === "title" && sortOrder === "desc" && (
                              <BsChevronDown className="sort-icon active" />
                            )}
                            {!(sortField === "title") && (
                              <BsChevronDown className="sort-icon default" />
                            )}
                          </span>
                        </span>
                      </th>
                    )}
                    {visibleFields.includes("status") && (
                      <th
                        key="status"
                        onClick={() => handleSort("status")}
                        className={`sortable ${
                          sortField === "status"
                            ? sortOrder === "asc"
                              ? "asc"
                              : "desc"
                            : ""
                        }`}
                      >
                        <span className="header-label">
                          Статус
                          <span className="sort-icons">
                            {sortField === "status" && sortOrder === "asc" && (
                              <BsChevronUp className="sort-icon active" />
                            )}
                            {sortField === "status" && sortOrder === "desc" && (
                              <BsChevronDown className="sort-icon active" />
                            )}
                            {!(sortField === "status") && (
                              <BsChevronDown className="sort-icon default" />
                            )}
                          </span>
                        </span>
                      </th>
                    )}
                    {visibleFields.includes("customer") && (
                      <th
                        key="customer"
                        onClick={() => handleSort("customer")}
                        className={`sortable ${
                          sortField === "customer"
                            ? sortOrder === "asc"
                              ? "asc"
                              : "desc"
                            : ""
                        }`}
                      >
                        <span className="header-label">
                          Заказчик
                          <span className="sort-icons">
                            {sortField === "customer" &&
                              sortOrder === "asc" && (
                                <BsChevronUp className="sort-icon active" />
                              )}
                            {sortField === "customer" &&
                              sortOrder === "desc" && (
                                <BsChevronDown className="sort-icon active" />
                              )}
                            {!(sortField === "customer") && (
                              <BsChevronDown className="sort-icon default" />
                            )}
                          </span>
                        </span>
                      </th>
                    )}
                    {visibleFields.includes("users") && (
                      <th
                        key="users"
                        onClick={() => handleSort("users")}
                        className={`sortable ${
                          sortField === "users"
                            ? sortOrder === "asc"
                              ? "asc"
                              : "desc"
                            : ""
                        }`}
                      >
                        <span className="header-label">
                          Участники
                          <span className="sort-icons">
                            {sortField === "users" && sortOrder === "asc" && (
                              <BsChevronUp className="sort-icon active" />
                            )}
                            {sortField === "users" && sortOrder === "desc" && (
                              <BsChevronDown className="sort-icon active" />
                            )}
                            {!(sortField === "users") && (
                              <BsChevronDown className="sort-icon default" />
                            )}
                          </span>
                        </span>
                      </th>
                    )}
                    {ProjectFields.map(
                      (field) =>
                        visibleFields.includes(field.name) &&
                        ![
                          "id",
                          "title",
                          "status",
                          "customer",
                          "users",
                        ].includes(field.name) && (
                          <th
                            key={field.name}
                            onClick={() => handleSort(field.name)}
                            className={`sortable ${
                              sortField === field.name
                                ? sortOrder === "asc"
                                  ? "asc"
                                  : "desc"
                                : ""
                            }`}
                          >
                            <span className="header-label">
                              {field.label}
                              <span className="sort-icons">
                                {sortField === field.name &&
                                  sortOrder === "asc" && (
                                    <BsChevronUp className="sort-icon active" />
                                  )}
                                {sortField === field.name &&
                                  sortOrder === "desc" && (
                                    <BsChevronDown className="sort-icon active" />
                                  )}
                                {!(sortField === field.name) && (
                                  <BsChevronDown className="sort-icon default" />
                                )}
                              </span>
                            </span>
                          </th>
                        )
                    )}
                  </tr>
                </thead>

                <tbody>
                  {currentItems.map((project) =>
                    project && project.id !== undefined ? (
                      <tr key={project.id}>
                        <td className="action_delete">
                          <input
                            type="checkbox"
                            checked={selectedProjects.includes(project.id!)}
                            onChange={() => handleCheckboxChange(project.id!)}
                          />
                        </td>
                        <td className="action">
                          <img
                            src={
                              activeIdEditIcon === project.id
                                ? icon_edit_active
                                : icon_edit
                            }
                            className="edit_icon"
                            alt="Редактировать"
                            onClick={() => handleOpenModal(project)}
                            style={{ cursor: "pointer", paddingLeft: "10px" }}
                            onMouseEnter={() => {
                              setActiveIdEditIcon(project.id || 0);
                            }}
                            onMouseLeave={() => {
                              setActiveIdEditIcon(0);
                            }}
                          />
                        </td>
                        {visibleFields.includes("id") && (
                          <td
                            key={`${project.id}_id`}
                            onClick={() => handleCellClick(project.id!, "id")}
                            className={`table-cell ${
                              expandedCells.has(`${project.id}_id`)
                                ? "expanded"
                                : ""
                            }`}
                          >
                            {project.id}
                          </td>
                        )}
                        {visibleFields.includes("title") && (
                          <td
                            key={`${project.id}_title`}
                            onClick={() =>
                              handleCellClick(project.id!, "title")
                            }
                            className={`table-cell ${
                              expandedCells.has(`${project.id}_title`)
                                ? "expanded"
                                : ""
                            }`}
                          >
                            {project.title}
                          </td>
                        )}
                        {visibleFields.includes("status") && (
                          <td
                            key={`${project.id}_status`}
                            onClick={() =>
                              handleCellClick(project.id!, "status")
                            }
                            className={`table-cell ${
                              expandedCells.has(`${project.id}_status`)
                                ? "expanded"
                                : ""
                            }`}
                          >
                            <span
                              className="status-content"
                              style={{
                                backgroundColor:
                                  statusColors[
                                    project.status as keyof typeof statusColors
                                  ] || "#000",
                              }}
                            >
                              {statusTranslations[
                                project.status as keyof typeof statusTranslations
                              ] || project.status}
                            </span>
                          </td>
                        )}
                        {visibleFields.includes("customer") && (
                          <td
                            key={`${project.id}_customer`}
                            onClick={() =>
                              handleCellClick(project.id!, "customer")
                            }
                            className={`table-cell ${
                              expandedCells.has(`${project.id}_customer`)
                                ? "expanded"
                                : ""
                            }`}
                          >
                            {project.customer}
                          </td>
                        )}
                        {visibleFields.includes("users") && (
                          <td
                            key={`${project.id}_users`}
                            onClick={() =>
                              handleCellClick(project.id!, "users")
                            }
                            className={`table-cell ${
                              expandedCells.has(`${project.id}_users`)
                                ? "expanded"
                                : ""
                            }`}
                          >
                            {project.users &&
                              project.users
                                .map((user) =>
                                  typeof user === "object" && "lastName" in user
                                    ? user.lastName
                                    : ""
                                )
                                .filter((name) => name !== "")
                                .join(", ")}
                          </td>
                        )}

                        {ProjectFields.map(
                          (field) =>
                            visibleFields.includes(field.name) &&
                            ![
                              "id",
                              "title",
                              "status",
                              "customer",
                              "users",
                            ].includes(field.name) && (
                              <td
                                key={`${project.id}_${field.name}`}
                                onClick={() =>
                                  handleCellClick(project.id!, field.name)
                                }
                                className={`table-cell ${
                                  expandedCells.has(
                                    `${project.id}_${field.name}`
                                  )
                                    ? "expanded"
                                    : ""
                                }`}
                              >
                                {field.name === "start_date" ||
                                field.name === "end_date"
                                  ? typeof project[
                                      field.name as keyof Project
                                    ] === "string"
                                    ? new Date(
                                        project[
                                          field.name as keyof Project
                                        ] as string
                                      ).toLocaleDateString()
                                    : ""
                                  : project[
                                      field.name as keyof Project
                                    ]?.toString() || ""}
                              </td>
                            )
                        )}
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
          )}
          <Pagination
            currentPage={currentPage}
            itemsPerPage={sumPage}
            totalItems={projects.length}
            paginate={paginate}
          />
        </>
      )}
    </div>
  );
};

export default ProjectTablePage;
