import React, { useState, useEffect } from "react";
import {
  fetchOrganization,
  createOrganization,
  updateOrganization,
  deleteOrganization,
  Organization,
} from "../api/customersTasks/api";
import { useLocation } from "react-router-dom";
import UniversalForm from "../components/UniversalForm/UniversalForm";
import Pagination from "../components/Pagination/Pagination";
import icon_delete from "../images/icons/icon_delete.svg";
import icon_delete_active from "../images/icons/icon_delete_hover.svg";
import icon_all from "../images/icons/icon_all.svg";
import icon_all_active from "../images/icons/all_icon_hover.svg";
import icon_plus_active from "../images/icons/icon_plus_hover.svg";
import icon_plus from "../images/icons/icon_plus.svg";
import icon_search from "../images/icons/icon_search.svg";
import icon_search_active from "../images/icons/icon_search_hover.svg";
import AdminBreadcrumbs from "../components/AdminBreadcrumbs/AdminBreadcrumbs";
import FieldSelector from "../components/FieldSelector/FieldSelector";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import icon_edit from "../images/icons/icon_edit.svg";
import icon_edit_active from "../images/icons/icon_edit_hover.svg";
import "./styles/Table.css";
import { RiseLoader } from "react-spinners";
import { useLogout } from "../helpers/hooks/useLogout";
import { useDebounce } from "../helpers/hooks/useDebounce";

interface CustomerTablePageProps {}

const CustomerFields = [
  { name: "id", label: "ID", placeholder: "ID", type: "number" },
  {
    name: "title",
    label: "Наименование",
    placeholder: "Наименование",
    type: "text",
  },
  { name: "ogrn", label: "ОГРН", placeholder: "ОГРН", type: "text" },
  { name: "inn", label: "ИНН", placeholder: "ИНН", type: "text" },
  { name: "kpp", label: "КПП", placeholder: "КПП", type: "text" },
  { name: "okpo", label: "ОКПО", placeholder: "ОКПО", type: "text" },
  {
    name: "legal_address",
    label: "Юр. адрес",
    placeholder: "Юр. адрес",
    type: "text",
  },
  {
    name: "actual_address",
    label: "Физ. адрес",
    placeholder: "Физ. адрес",
    type: "text",
  },
  {
    name: "bank_requisites",
    label: "Банковские реквизиты",
    placeholder: "Банковские реквизиты",
    type: "text",
  },
  {
    name: "contact_data",
    label: "Контакты",
    placeholder: "Контакты",
    type: "text",
  },
  {
    name: "contact_person",
    label: "Контактное лицо",
    placeholder: "Контактноелицо2",
    type: "text",
  },

  {
    name: "executive_office",
    label: "Наименование исполнительного органа",
    placeholder: "Наименование исполнительного органа",
    type: "text",
  },
  /*   {
    name: "contact_person_id",
    label: "Идентификатор контактного лица",
    placeholder: "Идентификатор контактного лица",
    type: "number",
  }, */
  {
    name: "comment",
    label: "Комментарий",
    placeholder: "Комментарий",
    type: "text",
  },
];

const CustomerTablePage: React.FC<CustomerTablePageProps> = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shouldAdd = searchParams.get("add") === "true";

  const [activeAllIcon, setActiveAllIcon] = useState(icon_all);
  const [activePlusIcon, setActivePlusIcon] = useState(icon_plus);
  const [activeSearchIcon, setActiveSearchIcon] = useState(icon_search);
  const [activeDeleteIcon, setActiveDeleteIcon] = useState(icon_delete);
  const [activeIdEditIcon, setActiveIdEditIcon] = useState(0);

  const [projects, setProjects] = useState<Organization[]>([]);
  const [isModalOpen, setModalOpen] = useState(shouldAdd);
  const [currentProject, setCurrentProject] = useState<Organization | null>(
    null
  );
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const itemsPerPage = 4;
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [searchText, setSearchText] = useState<string>("");

  const debouncedSearchText = useDebounce(searchText);

  const [selectedCustomer, setSelectedCustomer] = useState<number[]>([]);
  const sumPage = 4;
  const [breadcrumbs, setBreadcrumbs] = useState<
    { name: string; path: string }[]
  >([]);

  const [visibleFields, setVisibleFields] = useState<string[]>(
    CustomerFields.map((field) => field.name)
  );

  useLogout();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const fetchedProjects = await fetchOrganization(
          debouncedSearchText,
          sortOrder
        );
        console.log("Проекты после фильтрации:", fetchedProjects);
        setProjects(fetchedProjects);
      } catch (err) {
        console.error("Ошибка при загрузке проектов:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sortOrder, sortField, debouncedSearchText]);

  useEffect(() => {
    setModalOpen(shouldAdd);
  }, [shouldAdd]);

  useEffect(() => {
    if (currentProject) {
      setBreadcrumbs([
        { name: "Панель администратора", path: "/main-admin-panel" },
        { name: "Заказчики", path: "/customer" },
        { name: currentProject.title || "", path: "" },
      ]);
    } else if (shouldAdd) {
      setBreadcrumbs([
        { name: "Панель администратора", path: "/main-admin-panel" },
        { name: "Заказчики", path: "/customer" },
        { name: "Добавление нового проекта", path: "" },
      ]);
    } else {
      setBreadcrumbs([
        { name: "Панель администратора", path: "/main-admin-panel" },
        { name: "Заказчики", path: "/customer" },
      ]);
    }
  }, [currentProject, shouldAdd]);

  const handleOpenModal = (project: Organization | null) => {
    setCurrentProject(project);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentProject(null);
  };

  const handleSubmit = async (organizationData: Partial<Organization>) => {
    try {
      const { contact_person, ...rest } = organizationData;
      const transformedData = {
        ...rest,
        contact_person_id: contact_person || undefined,
      };

      console.log("Данные для создания новой организации:", transformedData);

      if (currentProject) {
        const updatedOrganization = await updateOrganization(
          currentProject.id!,
          transformedData as Organization
        );

        if (updatedOrganization) {
          setProjects((prevProjects) =>
            prevProjects.map((p) =>
              p.id === currentProject.id ? updatedOrganization : p
            )
          );
        }
      } else {
        const newOrganization = await createOrganization(
          transformedData as Organization
        );

        console.log("Созданная новая организация:", newOrganization);

        if (newOrganization) {
          setProjects((prevProjects) => [...prevProjects, newOrganization]);
        }
      }

      handleCloseModal();
    } catch (err) {
      console.error("Ошибка при создании/обновлении организации:", err);
    }
  };

  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);

  const handleCheckboxChange = (id: number) => {
    setSelectedProjects((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((i) => i !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setSelectedProjects(checked ? projects.map((project) => project.id!) : []);
  };

  const handleBulkDelete = async () => {
    try {
      for (const id of selectedProjects) {
        await deleteOrganization(id);
      }
      setProjects((prevProjects) =>
        prevProjects.filter((p) => !selectedProjects.includes(p.id!))
      );
      setSelectedProjects([]);
    } catch (err) {
      console.error("Ошибка при удалении проектов:", err);
    }
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleSort = (fieldName: string) => {
    if (sortField === fieldName) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortField(fieldName);
      setSortOrder("asc");
    }
  };

  const sortedProjects = projects.slice().sort((a, b) => {
    if (sortField) {
      const aValue = a[sortField as keyof Organization];
      const bValue = b[sortField as keyof Organization];

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      }
    }
    return 0;
  });
  const lastItem = currentPage * sumPage;
  const firstItem = lastItem - sumPage;
  const currentItems = sortedProjects.slice(firstItem, lastItem);

  const [expandedCells, setExpandedCells] = useState<Set<string>>(new Set());

  const handleCellClick = (id: number, fieldName: string) => {
    const cellKey = `${id}_${fieldName}`;
    setExpandedCells((prevExpandedCells) => {
      const newExpandedCells = new Set(prevExpandedCells);
      if (newExpandedCells.has(cellKey)) {
        newExpandedCells.delete(cellKey);
      } else {
        newExpandedCells.add(cellKey);
      }
      return newExpandedCells;
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const handleToggleField = (fieldName: string) => {
    setVisibleFields((prevFields) =>
      prevFields.includes(fieldName)
        ? prevFields.filter((field) => field !== fieldName)
        : [...prevFields, fieldName]
    );
  };

  return (
    <div className="interns-table-page">
      <AdminBreadcrumbs breadcrumbs={breadcrumbs} />
      <div className="control-panel">
        <p className="control-panel_title">Заказчики</p>
        {!isModalOpen && (
          <>
            <div className="control-panel_stage"></div>
            <div className="left_container">
              <FieldSelector
                fields={CustomerFields}
                visibleFields={visibleFields}
                onToggleField={handleToggleField}
              />
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Найти"
                  value={searchText}
                  onChange={handleSearchChange}
                  className="search-input"
                />
                <img
                  src={activeSearchIcon}
                  alt="Искать"
                  className="search-icon"
                  onMouseEnter={() => {
                    setActiveSearchIcon(icon_search_active);
                  }}
                  onMouseLeave={() => {
                    setActiveSearchIcon(icon_search);
                  }}
                />
              </div>
            </div>
          </>
        )}
        {!currentProject && (
          <div
            className={`addButton_position ${isModalOpen ? "addButton_largeOffset" : ""}`}
          >
            <div className="addButton_position_content">
              <button
                className={isModalOpen ? "inactive" : "active"}
                onClick={handleCloseModal}
                onMouseEnter={() => {
                  setActiveAllIcon(icon_all_active);
                }}
                onMouseLeave={() => {
                  setActiveAllIcon(icon_all);
                }}
              >
                <img src={activeAllIcon} alt="Все" />
                Все
              </button>
              <button
                className={isModalOpen ? "active" : "inactive"}
                onClick={() => handleOpenModal(null)}
                onMouseEnter={() => {
                  setActivePlusIcon(icon_plus_active);
                }}
                onMouseLeave={() => {
                  setActivePlusIcon(icon_plus);
                }}
              >
                <img src={activePlusIcon} alt="Добавить" />
                Добавить
              </button>
            </div>
          </div>
        )}
      </div>
      {isModalOpen ? (
        <UniversalForm
          item={currentProject}
          onSubmit={handleSubmit}
          fields={CustomerFields}
        />
      ) : (
        <>
          <div className="all_delete">
            <button
              className="deleteButton"
              onClick={handleBulkDelete}
              disabled={selectedProjects.length === 0}
              onMouseEnter={() => {
                selectedProjects.length > 0 &&
                  setActiveDeleteIcon(icon_delete_active);
              }}
              onMouseLeave={() => {
                selectedProjects.length > 0 && setActiveDeleteIcon(icon_delete);
              }}
            >
              <img src={activeDeleteIcon} alt="Удалить" />
            </button>
          </div>
          {isLoading ? (
            <div
              style={{
                height: 400,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2 style={{ color: "#656468" }}>Нет данных</h2>
            </div>
          ) : (
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={selectedProjects.length === projects.length}
                      />
                    </th>
                    <th className="action"></th>
                    {CustomerFields.map(({ name, label }) =>
                      visibleFields.includes(name) ? (
                        <th
                          key={name}
                          onClick={() => handleSort(name)}
                          className={`sortable ${
                            sortField === name
                              ? sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : ""
                          }`}
                        >
                          <span className="header-label">
                            {label}
                            <span className="sort-icons">
                              {sortField === name && sortOrder === "asc" && (
                                <BsChevronUp className="sort-icon active" />
                              )}
                              {sortField === name && sortOrder === "desc" && (
                                <BsChevronDown className="sort-icon active" />
                              )}
                              {!(sortField === name) && (
                                <BsChevronDown className="sort-icon default" />
                              )}
                            </span>
                          </span>
                        </th>
                      ) : null
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((project) => (
                    <tr key={project.id}>
                      <td className="action_delete">
                        <input
                          type="checkbox"
                          checked={selectedProjects.includes(project.id!)}
                          onChange={() => handleCheckboxChange(project.id!)}
                        />
                      </td>
                      <td className="action">
                        <img
                          src={
                            activeIdEditIcon === project.id
                              ? icon_edit_active
                              : icon_edit
                          }
                          alt="Редактировать"
                          className="edit_icon"
                          onClick={() => handleOpenModal(project)}
                          style={{ cursor: "pointer", paddingLeft: "10px" }}
                          onMouseEnter={() => {
                            setActiveIdEditIcon(project.id);
                          }}
                          onMouseLeave={() => {
                            setActiveIdEditIcon(0);
                          }}
                        />
                      </td>
                      {CustomerFields.map(({ name }) =>
                        visibleFields.includes(name) ? (
                          <td
                            key={name}
                            onClick={() => handleCellClick(project.id!, name)}
                            className={
                              expandedCells.has(`${project.id}_${name}`)
                                ? "expanded"
                                : ""
                            }
                          >
                            {name === "contact_person" && project.contact_person
                              ? `${project.contact_person.firstName || ""} ${project.contact_person.lastName || ""} ${project.contact_person.middleName || ""}`
                              : project[
                                  name as keyof Organization
                                ]?.toString() || ""}
                          </td>
                        ) : null
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
      <Pagination
        currentPage={currentPage}
        itemsPerPage={sumPage}
        totalItems={projects.length}
        paginate={paginate}
      />
    </div>
  );
};

export default CustomerTablePage;
