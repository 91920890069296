import React from 'react';
import './DocumentCard.css';
import { downloadDocument } from './../../api/documents/DocumentsApi';

import document_pdf from "../../images/img/document_pdf.png";
import document_doc from "../../images/img/document_doc.png";
import document_txt from "../../images/img/document_txt.png";
import icon_open_docs from "../../images/icons/icon_open_docs.svg";
import icon_download from "../../images/icons/icon_download.svg";

interface DocumentCardProps {
  filename: string;
  path: string;
  url: string;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ filename, path, url }) => {
  const getDocumentImage = (filename: string) => {
    const extension = filename.split('.').pop();
    switch (extension) {
      case 'pdf':
        return document_pdf;
      case 'doc':
      case 'docx':
        return document_doc;
      case 'txt':
        return document_txt;
      default:
        return document_pdf;
    }
  };

  const handleDownload = async () => {
    try {
      await downloadDocument(path);
    } catch (error) {
      console.error('Ошибка при скачивании документа', error);
    }
  };

  return (
    <div className="document-card">
      <img src={getDocumentImage(filename)} alt="document" className="document-image" />
      <div className="document-details">
        <h2 className="document-title">{filename}</h2>
        <div className="document-actions">
          <a href={url} target="_blank" rel="noopener noreferrer" className="document-button">
            <img src={icon_open_docs} alt="open" className="button-icon" />
            Открыть
          </a>
          <button className="document-button" onClick={handleDownload}>
            <img src={icon_download} alt="download" className="button-icon" />
            Скачать
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocumentCard;
