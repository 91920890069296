import React, { useState, useEffect, useRef } from "react";
import "./SelectStage.css";
import stage from "../../images/icons/icon_stage.svg";
import stage_active from "../../images/icons/icon_fitler_active.svg";

type Option = {
  value: string;
  label: string;
};

type SelectStageProps = {
  options: Option[];
  selectedValue: string;
  onChange: (value: string) => void;
};

const SelectStage: React.FC<SelectStageProps> = ({
  options,
  selectedValue,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeStageIcon, setActiveStageIcon] = useState(stage);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (value: string) => {
    onChange(value);
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div
        className="dropdown-header"
        onClick={handleToggle}
        onMouseEnter={() => {
          setActiveStageIcon(stage_active);
        }}
        onMouseLeave={() => {
          setActiveStageIcon(stage);
        }}
      >
        <img src={activeStageIcon} alt="filter" className="filter-icon" />
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {options.map((option) => (
            <label
              key={option.value}
              className={`dropdown-item ${option.value === selectedValue ? "selected" : ""}`}
            >
              <input
                type="radio"
                name="stage"
                value={option.value}
                checked={option.value === selectedValue}
                onChange={() => handleSelect(option.value)}
                className="status-radio"
              />
              <span>{option.label}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectStage;
