import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { fetchProjects, updateProject, ProjectForView, User } from "../api/projectTasks/ProjectForViewApi";
import Pagination from "../components/Pagination/Pagination";
import AdminBreadcrumbs from "../components/AdminBreadcrumbs/AdminBreadcrumbs";
import FieldSelector from "../components/FieldSelector/FieldSelector";
import UniversalForm from "../components/UniversalForm/UniversalForm";
import icon_search from "../images/icons/icon_search.svg";
import icons_search_active from "../images/icons/icon_search_hover.svg";
import icon_tasks from "../images/icons/icon_tasks.svg";
import Snackbar, { SnackbarType } from "../components/Snackbar/Snackbar";

import { BsChevronUp, BsChevronDown } from "react-icons/bs";

import "./styles/Table.css";
import { RiseLoader } from "react-spinners";
import { useLogout } from "../helpers/hooks/useLogout";
import { useDebounce } from "../helpers/hooks/useDebounce";

const ProjectFields = [
  {
    name: "title",
    label: "Наименование проекта",
    placeholder: "Наименование проекта",
    type: "text",
  },
  {
    name: "status",
    label: "Статус",
    placeholder: "Выберите статус",
    type: "select",
    options: [
      { value: "COMPLETED", label: "Завершен" },
      { value: "INPROGRESS", label: "В процессе" },
      { value: "NOTSTARTED", label: "Не начато" },
      { value: "CHECK", label: "Проверка" },
      { value: "CANCEL", label: "Отмена" },
    ],
  },
  {
    name: "start_date",
    label: "Дата начала",
    placeholder: "Начало",
    type: "text",
  },
  {
    name: "end_date",
    label: "Дата окончания",
    placeholder: "Конец",
    type: "text",
  },
  {
    name: "users",
    label: "Участники",
    placeholder: "Участники",
    type: "text",
  },
  {
    name: "comment",
    label: "Комментарий",
    placeholder: "Комментарий",
    type: "text",
  },
];

const statusTranslations: { [key: string]: string } = {
  COMPLETED: "Завершен",
  INPROGRESS: "В процессе",
  NOTSTARTED: "Не начато",
  CHECK: "Проверка",
  CANCEL: "Отмена",
};

const statusColors: { [key: string]: string } = {
  COMPLETED: "#8beba0",
  INPROGRESS: "#de8beb",
  NOTSTARTED: "#8bc8eb",
  CHECK: "#ebdc8b",
  CANCEL: "#EB9C8B",
};

const ProjectViewPage: React.FC = () => {
  useLogout();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [activeSearchIcon, setActiveSearchIcon] = useState(icon_search);

  const [projects, setProjects] = useState<ProjectForView[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [initialProjectData, setInitialProjectData] =
    useState<Partial<ProjectForView> | null>(null);
  const sumPage = 5;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentProject, setCurrentProject] = useState<ProjectForView | null>(null);
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [searchText, setSearchText] = useState<string>("");
  const [visibleFields, setVisibleFields] = useState<string[]>(
    ProjectFields.map((field) => field.name)
  );
  const [selectedProject, setSelectedProject] = useState<ProjectForView | null>(null);
  const [snackbar, setSnackbar] = useState<{
    type: SnackbarType;
    open: boolean;
  }>({ type: "successChange", open: false });

  const debouncedSearchText = useDebounce(searchText);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userRole = localStorage.getItem("user_role");

  useLogout();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        console.log("Видимые поля:", visibleFields);

        const fetchedProjects = await fetchProjects(
          "ALL",
          debouncedSearchText,
          sortField
            ? sortField + (sortOrder === "desc" ? "_desc" : "")
            : undefined
        );
        setProjects(fetchedProjects);
        console.log("Загруженные проекты:", fetchedProjects);
      } catch (err) {
        console.error("Ошибка при загрузке проектов:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sortField, sortOrder, debouncedSearchText]);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleSort = (fieldName: string) => {
    if (sortField === fieldName) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortField(fieldName);
      setSortOrder("asc");
    }
  };

  const sortedProjects = projects.slice().sort((a, b) => {
    if (sortField) {
      const aValue = a[sortField as keyof ProjectForView] as unknown as string;
      const bValue = b[sortField as keyof ProjectForView] as unknown as string;

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
    }
    return 0;
  });

  const lastItem = currentPage * sumPage;
  const firstItem = lastItem - sumPage;
  const currentItems = sortedProjects.slice(firstItem, lastItem);

  const handleRowClick = (project: ProjectForView) => {
    setSelectedProject(project);
    setInitialProjectData(project);
  };

  const handleCloseForm = () => {
    setSelectedProject(null);
    setInitialProjectData(null);
  };

  const handleSearchClick = () => {
    setSearchQuery(searchText);
    setCurrentPage(1);
  };

  // const handleFormSubmit = async (updatedData: Partial<Project>) => {
  //   if (selectedProject && selectedProject.id) {
  //     try {
  //       if (updatedData.users && Array.isArray(updatedData.users)) {
  //         updatedData.users = updatedData.users.map((user) =>
  //           typeof user === "object" && "id" in user ? user.id : user
  //         );
  //       }
  //       await updateProject(
  //         selectedProject.id,
  //         updatedData as Partial<Project> & { users?: number[] }
  //       );
  //       const updatedProjects = projects.map((project) =>
  //         project.id === selectedProject.id
  //           ? { ...project, ...updatedData }
  //           : project
  //       );
  //       setProjects(updatedProjects);
  //       setSnackbar({ type: "successChange", open: true });
  //       handleCloseForm();
  //     } catch (error) {
  //       console.error("Ошибка при обновлении проекта:", error);
  //       setSnackbar({ type: "errorSendingData", open: true });
  //     }
  //   }
  // };

  const handleFormSubmit = async (updatedData: Partial<ProjectForView>) => {
    if (selectedProject && selectedProject.id) {
      try {
        await updateProject(selectedProject.id, updatedData);
        const updatedProjects = projects.map((project) =>
          project.id === selectedProject.id ? { ...project, ...updatedData } : project
        );
        setProjects(updatedProjects);
        handleCloseForm();
      } catch (error) {
        console.error('Ошибка при обновлении проекта:', error);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const renderFieldValue = (fieldName: string, value: any): React.ReactNode => {
    switch (fieldName) {
      case 'status':
        return (
          <span
            className="status-content"
            style={{
              backgroundColor:
                statusColors[(value as string) || ''] || '#000',
            }}
          >
            {statusTranslations[(value as string)] || value}
          </span>
        );
      case 'start_date':
      case 'end_date':
        return typeof value === 'string'
          ? new Date(value).toLocaleDateString()
          : '';
      case 'users':
        if (Array.isArray(value)) {
          return value
            .map((user: User) => user.lastName || '')
            .filter((lastName) => lastName)
            .join(', ');
        }
        return '';
      case 'comment':
        return value || '';
      default:
        return value?.toString() || '';
    }
  };
  

  const breadcrumbs =
    selectedProject && selectedProject.title
      ? [
          { name: "Проекты", path: "/project_view" },
          {
            name: selectedProject.title || "Проект",
            path: "/project_view",
          },
        ]
      : [{ name: "Проекты", path: "/project_view" }];

  return (
    <div className="interns-table-page">
      <AdminBreadcrumbs breadcrumbs={breadcrumbs} />
      {selectedProject ? (
        <div className="form-container">
          {userRole === "CUSTOMER" && (
            <div className="task-border">
              <Link
                to={`/tasks?projectId=${selectedProject?.id}`}
                className="task-button"
              >
                <img src={icon_tasks} alt="Задача" className="task-icon" />
                <span className="task-text">Задачи по проектам</span>
              </Link>
            </div>
          )}
          <UniversalForm
            item={selectedProject}
            onSubmit={handleFormSubmit}
            fields={ProjectFields.map((field) => ({
              ...field,
              required: false,
              type: field.name === "status" ? "select" : field.type,
            }))}
          />
        </div>
      ) : (
        <>
          <div className="control-panel">
            <p className="control-panel_title" style={{ marginRight: "50%" }}>
              Проекты
            </p>
            <div className="left_content">
              <FieldSelector
                fields={ProjectFields}
                visibleFields={visibleFields}
                onToggleField={(fieldName) =>
                  setVisibleFields((prevFields) =>
                    prevFields.includes(fieldName)
                      ? prevFields.filter((field) => field !== fieldName)
                      : [...prevFields, fieldName]
                  )
                }
              />
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Найти"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className="search-input"
                />
                <img
                  src={activeSearchIcon}
                  alt="Искать"
                  className="search-icon"
                  onClick={handleSearchClick}
                  style={{ cursor: "pointer" }}
                  onMouseEnter={() => {
                    setActiveSearchIcon(icons_search_active);
                  }}
                  onMouseLeave={() => {
                    setActiveSearchIcon(icon_search);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="project-table">
            {!projects.length ? (
              <div
                style={{
                  height: 400,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <RiseLoader loading={!projects.length} size={40} color="red" />
              </div>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    {ProjectFields.map(
                      (field) =>
                        visibleFields.includes(field.name) && (
                          <th
                            key={field.name}
                            onClick={() => handleSort(field.name)}
                            className={`sortable ${
                              sortField === field.name
                                ? sortOrder === "asc"
                                  ? "asc"
                                  : "desc"
                                : ""
                            }`}
                          >
                            <span className="header-label">
                              {field.label}
                              <span className="sort-icons">
                                {sortField === field.name &&
                                  sortOrder === "asc" && (
                                    <BsChevronUp className="sort-icon active" />
                                  )}
                                {sortField === field.name &&
                                  sortOrder === "desc" && (
                                    <BsChevronDown className="sort-icon active" />
                                  )}
                                {sortField !== field.name && (
                                  <BsChevronDown className="sort-icon default" />
                                )}
                              </span>
                            </span>
                          </th>
                        )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((project) => (
                    <tr key={project.id} onClick={() => handleRowClick(project)} style={{ cursor: 'pointer' }}>
                      {ProjectFields.map((field) =>
                        visibleFields.includes(field.name) ? (
                          <td key={`${project.id}_${field.name}`}>
                            {renderFieldValue(field.name, project[field.name as keyof ProjectForView])}
                          </td>
                        ) : null
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <Pagination
              currentPage={currentPage}
              itemsPerPage={sumPage}
              totalItems={projects.length}
              paginate={paginate}
            />
          </div>
        </>
      )}
      {snackbar.open && (
        <Snackbar type={snackbar.type} onClose={handleSnackbarClose} />
      )}
    </div>
  );
};

export default ProjectViewPage;