import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./AdminBreadcrumbs.css";
import separatorIcon from "./../../images/icons/icon_separator.svg";

interface Breadcrumb {
  name: string;
  path: string;
}

interface AdminBreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

const AdminBreadcrumbs: React.FC<AdminBreadcrumbsProps> = ({ breadcrumbs }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBreadcrumbClick = (path: string) => {
    navigate(path);
  };

  const isMainPanel = location.pathname === '/main-admin-panel';

  return (
    <div className={`breadcrumb-container ${isMainPanel ? "main-panel" : "sub-page"}`}>
      
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={breadcrumb.path}>
          <span
            className={`breadcrumb-title ${index === breadcrumbs.length - 1 ? "breadcrumb-current" : ""}`}
            onClick={() => handleBreadcrumbClick(breadcrumb.path)}
          >
            {breadcrumb.name}
          </span>
          {/* {index < breadcrumbs.length - 1 && ( */}
            <img
              src={separatorIcon}
              alt="Separator"
              className="breadcrumb-separator"
            />
          {/* )} */}
        </React.Fragment>
      ))}
    </div>
  );
};

export default AdminBreadcrumbs;
