import React, { useEffect, useState } from "react";
import AdminBreadcrumbs from "../components/AdminBreadcrumbs/AdminBreadcrumbs";
import "./styles/Profile.css";
import AdminForm from "../components/Profile/AdminForm";
import CustomerForm from "../components/Profile/CustomerForm";
import SpecialistForm from "../components/Profile/SpecialistForm";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../helpers/hooks/useLogout";

const Profile: React.FC = () => {
  useLogout();
  const [userRole, setUserRole] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedRole = localStorage.getItem("user_role");
    if (storedRole) {
      setUserRole(storedRole);
    } else {
      navigate("/*");
    }
  }, [navigate]);

  if (!["ADMIN", "CUSTOMER", "SPECIALIST"].includes(userRole)) {
    navigate("/*");
    return null;
  }

  let profileComponent;
  switch (userRole) {
    case "ADMIN":
      profileComponent = <AdminForm />;
      break;
    case "CUSTOMER":
      profileComponent = <CustomerForm />;
      break;
    case "SPECIALIST":
      profileComponent = <SpecialistForm />;
      break;
    default:
      profileComponent = null; // В принципе, не должно достигаться
      break;
  }

  const breadcrumbs =
    userRole === "ADMIN"
      ? [
          { name: "Панель администратора", path: "/main-admin-panel" },
          { name: "Личный кабинет", path: "/profile" },
        ]
      : [{ name: "Личный кабинет", path: "/profile" }];

  return (
    <div className="profile">
      <AdminBreadcrumbs breadcrumbs={breadcrumbs} />
      {profileComponent}
    </div>
  );
};

export default Profile;
