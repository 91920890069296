import React, { useState, useEffect, useCallback } from "react";
import Calendar from "react-calendar";
import { ApiDocument, fetchDocuments } from "./../api/documents/DocumentsApi";
import { getUserProfile } from "./../api/profile/ProfileApi";
import "react-calendar/dist/Calendar.css";
import "./styles/DocumentsPage.css";
import AdminBreadcrumbs from "../components/AdminBreadcrumbs/AdminBreadcrumbs";
import DocumentCard from "../components/DocumentCard/DocumentCard";
import search_icon from "./../images/icons/icon_search.svg";
import calendar_icon from "./../images/icons/icon_calendar.svg";
import calendar_plus_icon from "./../images/icons/icon_calendar_plus.svg";
import { useLogout } from "../helpers/hooks/useLogout";
import { useDebounce } from "../helpers/hooks/useDebounce";
import { RiseLoader } from "react-spinners";

type Value = Date | [Date | null, Date | null] | null;

const formatDate = (date: Date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const formatDateInput = (date: Date | null) => {
  if (!date) return "";
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

const DocumentsPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [toggleState, setToggleState] = useState<"all" | "single" | "range">(
    "all"
  );

  const debouncedSearchText = useDebounce(searchTerm);

  const [date, setDate] = useState<Value>(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [documents, setDocuments] = useState<ApiDocument[]>([]);
  const [loading, setLoading] = useState(true);
  const [organizationId, setOrganizationId] = useState<number | null>(null);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleToggleChange = (state: "all" | "single" | "range") => {
    setToggleState(state);
    setSearchTerm("");
    if (state !== "all") {
      setShowCalendar(true);
    } else {
      setShowCalendar(false);
    }
  };

  const handleDateChange = (value: Value) => {
    setDate(value);
    setShowCalendar(false);
  };

  const getCalendarPosition = () => {
    switch (toggleState) {
      case "single":
        return "50%";
      case "range":
        return "62%";
      default:
        return "52%";
    }
  };

  useLogout();

  const loadDocuments = useCallback(async () => {
    if (!organizationId) return;

    setLoading(true);
    try {
      const startDate =
        toggleState === "single" && date instanceof Date
          ? formatDateInput(date)
          : Array.isArray(date) && date[0]
            ? formatDateInput(date[0])
            : undefined;
      const endDate =
        toggleState === "single" && date instanceof Date
          ? formatDateInput(new Date(date.getTime() + 86400000))
          : Array.isArray(date) && date[1]
            ? formatDateInput(date[1])
            : undefined;
      const documents = await fetchDocuments({
        organizationId,
        search: debouncedSearchText,
        startDate: startDate,
        endDate: endDate,
      });
      setDocuments(documents);
    } catch (error) {
      console.error("Ошибка при загрузке документов", error);
    } finally {
      setLoading(false);
    }
  }, [organizationId, debouncedSearchText, toggleState, date]);

  useEffect(() => {
    const loadUserProfile = async () => {
      try {
        const userProfile = await getUserProfile();
        if (userProfile && userProfile.organization) {
          setOrganizationId(userProfile.organization.id);
        }
      } catch (error) {
        console.error("Ошибка при загрузке профиля пользователя", error);
      }
    };

    loadUserProfile();
  }, []);

  useEffect(() => {
    if (organizationId) {
      loadDocuments();
    }
  }, [organizationId, searchTerm, toggleState, date]);

  const breadcrumbs = [{ name: "Документы", path: "/documents" }];

  return (
    <div className="documents">
      <AdminBreadcrumbs breadcrumbs={breadcrumbs} />
      <div className="documents-header">
        <p className="documents-title">Документы</p>
        <div className="documents-search">
          <input
            type="text"
            className="documents-search-input"
            placeholder="Найти по названию"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button className="documents-search-button" onClick={loadDocuments}>
            <img src={search_icon} alt="Search" />
          </button>
        </div>
        <div className="documents-toggle-container">
          <button
            className={`documents-toggle-button ${toggleState === "all" ? "active" : ""} all-docs`}
            onClick={() => handleToggleChange("all")}
          >
            Все
          </button>
          <div className="documents-separator"></div>
          <button
            className={`documents-toggle-button ${toggleState === "single" ? "active" : ""} single-date`}
            onClick={() => handleToggleChange("single")}
          >
            <img src={calendar_icon} className="calendar-icon" alt="Search" />
            {date instanceof Date ? formatDate(date) : "дд/мм/гггг"}
          </button>
          <div className="documents-separator"></div>
          <button
            className={`documents-toggle-button ${toggleState === "range" ? "active" : ""} range-date`}
            onClick={() => handleToggleChange("range")}
          >
            <img
              src={calendar_plus_icon}
              className="calendar-icon"
              alt="Search"
            />
            {Array.isArray(date) && date[0] && date[1]
              ? `${formatDate(date[0])} - ${formatDate(date[1])}`
              : "дд/мм/гггг - дд/мм/гггг"}
          </button>
        </div>
        {showCalendar && (
          <div
            className="calendar-popup"
            style={{ left: getCalendarPosition() }}
          >
            <Calendar
              onChange={handleDateChange}
              value={date}
              selectRange={toggleState === "range"}
            />
          </div>
        )}
      </div>
      <div className="documents-grid">
        {loading ? (
          <div
            style={{
              height: 400,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RiseLoader loading={loading} size={40} color="red" />
          </div>
        ) : documents.length === 0 ? (
          <div className="not-found-docs">Документы не найдены.</div>
        ) : (
          documents.map((document, index) => (
            <DocumentCard
              key={index}
              filename={document.name}
              path={document.path}
              url={document.url}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default DocumentsPage;
