import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import "./App.css";
import VacancyTablePage from "./pages/VacancyTablePage";
import InternsTablePage from "./pages/InternsTablePage";
import ProjectTablePage from "./pages/ProjectTablePage";
import { SidebarProvider } from "./components/SidebarContext/SidebarContext";
import Header from "./components/Header/Header";
import SpecialistTablePage from "./pages/SpecialistTablePage";
import TraineeTablePage from "./pages/TraineeTablePage";
import { LayoutProvider } from "./components/Context/LayoutContext";
import NotFoundPage from "./pages/NotFoundPage";
import PrivateRoute from "./api/auth/PrivateRoute";
import Profile from "./pages/Profile";
import CustomerTablePage from "./pages/CustomerTablePage";
import MainAdminPanel from "./pages/MainAdminPanel";
import LoginPage from "./pages/LoginPage";
import DocumentsPage from './pages/DocumentsPage';
import TaskTablePage from "./pages/TaskTablePage";
import ProjectViewPage from "./pages/ProjectViewPage";

const App: React.FC = () => {
  return (
    <SidebarProvider>
      <LayoutProvider>
        <Router>
          <MainLayout />
        </Router>
      </LayoutProvider>
    </SidebarProvider>
  ); 
};

const MainLayout: React.FC = () => {
  const location = useLocation();
  const hideSidebar = location.pathname === "/" || location.pathname === "/main-admin-panel";

  return (
    <>
      <Header />
      {!hideSidebar && <Sidebar />}
      <div className={hideSidebar ? "full-content" : "main-content"}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          {/* <Route path="/vacancies" element={<VacancyTablePage />} /> */}
          <Route path="/interns" element={<PrivateRoute element={<InternsTablePage />} roles={['ADMIN']} />} />
          <Route path="/project" element={<PrivateRoute element={<ProjectTablePage />} roles={['ADMIN']} />} />
          <Route path="/project_view" element={<PrivateRoute element={<ProjectViewPage />} roles={['SPECIALIST', 'CUSTOMER']} />} />
          <Route path="/tasks" element={<PrivateRoute element={<TaskTablePage />} roles={['SPECIALIST', 'CUSTOMER']} />} />
          <Route path="/specialist" element={<PrivateRoute element={<SpecialistTablePage />} roles={['ADMIN']} />} />
          <Route path="/customer" element={<PrivateRoute element={<CustomerTablePage />} roles={['ADMIN']} />} />
          <Route path="/trainee" element={<PrivateRoute element={<TraineeTablePage />} roles={['ADMIN']} />} />
          <Route path="/profile" element={<PrivateRoute element={<Profile />} roles={['ADMIN', 'SPECIALIST', 'CUSTOMER']} />} />
          <Route path="/documents" element={<PrivateRoute element={<DocumentsPage />} roles={['CUSTOMER']} />} />
          <Route path="/main-admin-panel" element={<PrivateRoute element={<MainAdminPanel />} roles={['ADMIN']} />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
