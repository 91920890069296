import React from "react";
import AdminMenu from "../components/AdminMenu/AdminMenu";
import Footer from "../components/Footer/Footer";
import AdminBreadcrumbs from "../components/AdminBreadcrumbs/AdminBreadcrumbs";
import { useLogout } from "../helpers/hooks/useLogout";

const MainAdminPanel: React.FC = () => {
  useLogout();
  const breadcrumbs = [
    { name: "Панель администратора", path: "/main-admin-panel" },
  ];

  return (
    <div className="main-admin">
      <AdminBreadcrumbs breadcrumbs={breadcrumbs} />
      <AdminMenu />
      <Footer />
    </div>
  );
};

export default MainAdminPanel;
