import axios from "axios";
import { Project } from "../projectTasks/api";

export interface Internship {
  id?: number;
  start_date?: string;
  end_date?: string;
  status?: string;
  user_id?: number;
  title?: string;
  description?: string;
  name?: string;
  practice_task?: string;
  isPractice?: boolean;
}

export interface User {
  id: number;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  direction?: string;
  phone?: string;
  telegram?: string;
  email?: string;
  login?: string;
  role?: string;
  status?: string;
  study_group?: string;
  plans?: string;
  position?: string;
  about?: string;
  portfolio?: string;
  note?: string;
  inn?: string;
  snils?: string;
  birthday?: string;
  passport_data?: string;
  registration_address?: string;
  living_address?: string;
  requisites?: string;
  customer?: string;
  npd_reference?: string;
  title?: string;
  competencies?: Competency[];
  internships?: Internship[];
  tasks?: Task[];
  projects?: Project[];
}

export interface Competency {
  id?: number;
  text?: string;
  users?: User[];
}
export interface Competencies {
  id?: number;
  text?: string;
}

export interface Task {
  id?: number;
  title?: string;
}
const API_URL = "https://cardfile.geryon.space/api";

const getAuthToken = () => {
  return localStorage.getItem("access_token");
};

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const fetchUserByStatus = async (
  status: string,
  stage: string,
  searchText: string,
  sortField?: string,
  sortOrder: "asc" | "desc" = "asc"
): Promise<User[]> => {
  try {
    const params = new URLSearchParams();
    params.append("status", status);

    if (stage) params.append("stage", stage);
    if (searchText) params.append("search", searchText);
    if (sortField) {
      params.append("sortField", sortField);
      params.append("sortOrder", sortOrder);
    }

    const response = await api.get<User[]>("/user/status", { params });
    console.log(`Ответ от сервера (стажеры, статус ${status}):`, response.data);

    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении стажеров со статусом ${status}:`,
      error
    );
    throw error;
  }
};
export const fetchAllInternships = async (): Promise<Internship[]> => {
  try {
    const response = await api.get<Internship[]>("/internship");
    console.log("Ответ от сервера (все стажировки):", response.data);
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении всех стажировок:", error);
    throw error;
  }
};
export const fetchProject = async (): Promise<Project[]> => {
  const response = await api.get<Project[]>("/project/list");
  const users = response.data;
  console.log("Все проекты :", users);
  return users;
};
export const fetchCompetency = async (): Promise<Competencies[]> => {
  const response = await api.get<Competencies[]>("/competency/titles");
  const users = response.data;
  console.log("Все компетенции :", users);
  return users;
};
export const fetchInterns = async (
  stage: string,
  searchText: string,
  sortField?: string,
  sortOrder: "asc" | "desc" = "asc"
): Promise<User[]> => {
  return fetchUserByStatus(
    "INTERNSHIP",
    stage,
    searchText,
    sortField,
    sortOrder
  );
};

export const fetchPractice = async (
  stage: string,
  searchText: string,
  sortField?: string,
  sortOrder: "asc" | "desc" = "asc"
): Promise<User[]> => {
  return fetchUserByStatus("PRACTICE", stage, searchText, sortField, sortOrder);
};

export const fetchSpecialist = async (
  stage: string,
  searchText: string,
  sortField?: string,
  sortOrder?: "asc" | "desc"
): Promise<User[]> => {
  return fetchUserByStatus(
    "SPECIALIST",
    stage,
    searchText,
    sortField,
    sortOrder
  );
};
export const createUser = async (userData: User): Promise<User> => {
  try {
    const cleanedUserData: Partial<User> = { ...userData };
    (Object.keys(cleanedUserData) as (keyof User)[]).forEach((key) => {
      if (cleanedUserData[key] === undefined || cleanedUserData[key] === "") {
        delete cleanedUserData[key];
      }
    });
    delete cleanedUserData.internships;
    delete cleanedUserData.competencies;
    delete cleanedUserData.projects;

    const response = await api.post("/user", cleanedUserData);
    const createdUser = response.data;

    if (userData.internships && userData.internships.length > 0) {
      await createInternships(createdUser.id, userData.internships);
    }

    if (userData.competencies && userData.competencies.length > 0) {
      await createCompetencies(createdUser.id, userData.competencies);
    }

    return createdUser;
  } catch (error) {
    console.error("Ошибка при создании пользователя:", error);
    throw error;
  }
};

export const createNewCompetency = async (
  userId: number,
  competencyText: string
): Promise<void> => {
  try {
    console.log("Создание новой компетенции:", competencyText);
    const competencyCreateData = {
      text: competencyText,
      users: [userId],
    };
    await api.post(`/competency`, competencyCreateData);
  } catch (error) {
    console.error("Ошибка при создании новой компетенции:", error);
    throw error;
  }
};

export const createCompetencies = async (
  userId: number,
  competencies: Competency[]
): Promise<void> => {
  try {
    if (competencies && competencies.length > 0) {
      const competencyCreatePromises = competencies.map(async (competency) => {
        if (competency.id) {
          console.log(
            `Связывание существующей компетенции (ID: ${competency.id}) с пользователем (ID: ${userId})`
          );
          // Используем метод обновления компетенции для добавления пользователя
          await api.patch(`/competency/${competency.id}`, {
            users: [userId]
          });
        } else {
          console.log("Создание новой компетенции:", competency.text);
          const competencyCreateData = {
            text: competency.text,
            users: [userId],
          };
          await api.post(`/competency`, competencyCreateData);
        }
      });

      await Promise.all(competencyCreatePromises);
    }
  } catch (error) {
    console.error("Ошибка при создании компетенций:", error);
    throw error;
  }
};

export const createInternships = async (userId: number, internships: Internship[]): Promise<void> => {
    try {
        if (internships && internships.length > 0) {
          const validInternships = internships.filter(internship =>
            internship.start_date ||
            internship.end_date || 
            internship.status ||    
            internship.practice_task 
        );
            if (validInternships.length > 0) {
                const internshipCreatePromises = validInternships.map(async (internship) => {
                    const internshipCreateData: Partial<Internship> = {
                        name: internship.name || "testname",
                        isPractice: internship.isPractice !== undefined ? internship.isPractice : false,
                        start_date: internship.start_date,
                        end_date: internship.end_date,
                        practice_task: internship.practice_task || " ",
                        status: internship.status === "INTERNSHIP" ? "INPROGRESS" : internship.status,
                        user_id: userId,
                    };

            console.log(
              "Данные стажировки перед отправкой:",
              internshipCreateData
            );

            await api.post(`/internship`, internshipCreateData);
          }
        );
        await Promise.all(internshipCreatePromises);
      }
    }
  } catch (error) {
    console.error("Ошибка при создании стажировки:", error);
    throw error;
  }
};

export const createPractice = async (userId: number, internships: Internship[]): Promise<void> => {
    try {
        if (internships && internships.length > 0) {
            const validInternships = internships.filter(internship =>
                internship.start_date ||
                internship.end_date ||
                internship.status ||
                internship.practice_task
            );
            if (validInternships.length > 0) {
                const internshipCreatePromises = validInternships.map(async (internship) => {
                    const internshipCreateData: Partial<Internship> = {
                        name: internship.name || "testname",
                        isPractice: internship.isPractice !== undefined ? internship.isPractice : true,
                        start_date: internship.start_date,
                        end_date: internship.end_date,
                        status: internship.status === "PRACTICE" ? "INPROGRESS" : internship.status,
                        practice_task: internship.practice_task || " ",
                        user_id: userId,
                    };

            console.log(
              "Данные стажировки перед отправкой:",
              internshipCreateData
            );

            await api.post(`/internship`, internshipCreateData);
          }
        );
        await Promise.all(internshipCreatePromises);
      }
    }
  } catch (error) {
    console.error("Ошибка при создании стажировки:", error);
    throw error;
  }
};

export const updateUser = async (
  id: number,
  userData: Partial<User>
): Promise<User> => {
  try {
    const dataToUpdate: Partial<User> = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      middleName: userData.middleName,
      direction: userData.direction,
      phone: userData.phone,
      telegram: userData.telegram,
      email: userData.email,
      login: userData.login,
      role: userData.role,
      study_group: userData.study_group,
      plans: userData.plans,
      position: userData.position,
      about: userData.about,
      portfolio: userData.portfolio,
      note: userData.note,
      inn: userData.inn,
      snils: userData.snils,
      birthday: userData.birthday,
      passport_data: userData.passport_data,
      registration_address: userData.registration_address,
      living_address: userData.living_address,
      requisites: userData.requisites,
      npd_reference: userData.npd_reference,
      customer: userData.customer,
      title: userData.title,
    };

    console.log(`Обновление пользователя с ID ${id}`);
    console.log("Данные для обновления:", dataToUpdate);
    const response = await api.patch(`/user/${id}`, dataToUpdate);

    console.log(
      "Ответ от сервера (редактирование пользователя):",
      response.data
    );

    if (userData.internships) {
      const existingInternships = userData.internships.filter(
        (internship) => internship.id
      );

      const internshipUpdatePromises = existingInternships.map(
        async (internship) => {
          const internshipUpdateData: Partial<Internship> = {
            start_date: internship.start_date || "2023-09-08T21:00:00.000Z",
            end_date: internship.end_date || "2023-09-08T21:00:00.000Z",
            status: internship.status || "SUCCESSCOMPLETED",
            practice_task: internship.practice_task || "test",
            user_id: id,
          };

          console.log(`Обновление стажировки с ID ${internship.id}`);
          console.log(
            "Данные для обновления стажировки:",
            internshipUpdateData
          );

          await api.patch(`/internship/${internship.id}`, internshipUpdateData);
        }
      );

      await Promise.all(internshipUpdatePromises);
    }

    if (userData.competencies) {
      const newCompetencies = userData.competencies.filter(
        (competency) => !competency.id
      );

      if (newCompetencies.length > 0) {
        console.log("Создание новых компетенций:", newCompetencies);
        await createCompetencies(id, newCompetencies);
      }
    }

    return response.data;
  } catch (error) {
    console.error("Ошибка при обновлении пользователя:", error);
    throw new Error("Ошибка при обновлении пользователя");
  }
};

export const deleteUser = async (id: number): Promise<void> => {
  try {
    console.log(`Попытка удалить пользователя с id ${id}`);
    const response = await api.delete(`/user/${id}`);
    console.log(`Ответ сервера при удалении пользователя:`, response);
    console.log(`Стажер с id ${id} успешно удален`);
  } catch (error) {
    console.error("Ошибка при удалении юзера:", error);
    throw error;
  }
};