import axios from "axios";
import { ContactPerson } from "../profile/interfaces/dto.profile";
import { User } from "../userTasks/userApi";

const API_URL = "https://cardfile.geryon.space/api";

export interface Organization {
  id: number;
  title?: string;
  ogrn?: string;
  inn?: string;
  kpp?: string;
  okpo?: string;
  legal_address?: string;
  actual_address?: string;
  bank_requisites?: string;
  contact_data?: string;
  executive_office?: number;
  contact_person?: User;
  comment?: string;
  contact_person_id?: number;
  projects?: number[];
}

const getAuthToken = () => {
  return localStorage.getItem("access_token");
};

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
export async function fetchOrganization(
  search?: string,
  orderDesc?: string,
  fields?: string
): Promise<Organization[]> {
  try {
    const params: any = {
      search,
      order_desc: orderDesc,
      fields,
    };

    console.log("Запрос на получение организаций с параметрами:", params);

    const response = await api.get<Organization[]>(`/organization`, { params });

    console.log("Ответ от сервера (организации):", response.data);

    return response.data;
  } catch (error) {
    console.error("Ошибка при получении организаций:", error);
    throw error;
  }
}

export const createOrganization = async (
  project: Partial<Organization>
): Promise<Organization> => {
  try {
    const cleanedProject: Partial<Organization> = { ...project };
    (Object.keys(cleanedProject) as (keyof Organization)[]).forEach((key) => {
      if (cleanedProject[key] === undefined || cleanedProject[key] === "") {
        delete cleanedProject[key];
      }
    });

    console.log("Отправка данных проекта на сервер:", cleanedProject);

    const response = await api.post("/organization", cleanedProject);
    return response.data;
  } catch (error) {
    console.error("Ошибка при создании проекта:", error);
    throw error;
  }
};

export const updateOrganization = async (
  organizationId: number,
  {
    title,
    ogrn,
    inn,
    kpp,
    okpo,
    legal_address,
    actual_address,
    bank_requisites,
    contact_data,
    /*  executive_office,
      contact_person, */
      contact_person,
    comment,
    contact_person_id,
    projects,
  }: Partial<Organization>
): Promise<Organization | null> => {
  try {
    const token = localStorage.getItem("access_token");
    if (!token) {
      throw new Error("No token found");
    }
    const dataToUpdate: Partial<Organization> = {
      title,
      ogrn,
      inn,
      kpp,
      okpo,
      legal_address,
      actual_address,
      bank_requisites,
      contact_data,
      comment,
      contact_person,
      contact_person_id,
      projects,
    };

    console.log("Отправляемые данные для обновления организации", dataToUpdate);

    const response = await axios.patch<Organization>(
      `${API_URL}/organization/${organizationId}`,
      dataToUpdate,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("Данные запроса организации", response.data);
    return response.data;
  } catch (error) {
    console.error("Ошибка при обновлении данных организации", error);
    return null;
  }
};

export const deleteOrganization = async (id: number): Promise<void> => {
  try {
    await api.delete(`/organization/${id}`);
  } catch (error) {
    console.error("Ошибка при удалении проекта:", error);
    throw error;
  }
};
