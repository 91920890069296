import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  fetchSpecialist,
  updateUser,
  deleteUser,
  fetchUserByStatus,
  User,
  createUser,
  Competency,
  createCompetencies,
  fetchCompetency,
} from "../api/userTasks/userApi";
import UniversalForm from "../components/UniversalForm/UniversalForm";
import Pagination from "../components/Pagination/Pagination";
import icon_delete from "../images/icons/icon_delete.svg";
import icon_delete_active from "../images/icons/icon_delete_hover.svg";
import icon_all from "../images/icons/icon_all.svg";
import icon_all_active from "../images/icons/all_icon_hover.svg";
import icon_plus from "../images/icons/icon_plus.svg";
import icon_plus_active from "../images/icons/icon_plus_hover.svg";
import icon_edit from "../images/icons/icon_edit.svg";
import icon_edit_active from "../images/icons/icon_edit_hover.svg";
import icon_search from "../images/icons/icon_search.svg";
import icon_search_active from "../images/icons/icon_search_hover.svg";
import AdminBreadcrumbs from "../components/AdminBreadcrumbs/AdminBreadcrumbs";
import FieldSelector from "../components/FieldSelector/FieldSelector";
import SelectStage from "../components/SelectStage/SelectStage";

import { BsChevronUp, BsChevronDown } from "react-icons/bs";

import "./styles/Table.css";
import { RiseLoader } from "react-spinners";
import { useLogout } from "../helpers/hooks/useLogout";
import { useDebounce } from "../helpers/hooks/useDebounce";
const SpecialistFields = [
  { name: "id", label: "ID", placeholder: "ID", type: "text" },
  {
    name: "firstName",
    label: "Имя",
    placeholder: "Имя",
    type: "text",
    required: true,
  },
  {
    name: "lastName",
    label: "Фамилия",
    placeholder: "Фамилия",
    type: "text",
    required: true,
  },
  {
    name: "middleName",
    label: "Отчество",
    placeholder: "Отчество",
    type: "text",
  },
  {
    name: "login",
    label: "Логин",
    placeholder: "Логин",
    type: "text",
  },
  {
    name: "telegram",
    label: "Телеграм",
    placeholder: "Телеграм",
    type: "text",
  },
  {
    name: "phone",
    label: "Телефон",
    placeholder: "Телефон",
    type: "text",
  },
  {
    name: "direction",
    label: "Профиль",
    placeholder: "Профиль",
    type: "text",
  },
  {
    name: "study_group",
    label: "Образование/Группа",
    placeholder: "Вектор развития",
    type: "text",
  },
  {
    name: "portfolio",
    label: "Портфолио",
    placeholder: "Портфолио",
    type: "text",
  },
  {
    name: "plans",
    label: "Вектор развития",
    placeholder: "Вектор развития",
    type: "text",
  },
  {
    name: "note",
    label: "Комментарий",
    placeholder: "Комментарий",
    type: "text",
  },
  {
    name: "inn",
    label: "ИНН",
    placeholder: "ИНН",
    type: "text",
  },
  {
    name: "snils",
    label: "СНИЛС",
    placeholder: "СНИЛС",
    type: "text",
  },
  {
    name: "birthday",
    label: "Дата рождения",
    placeholder: "Дата рождения",
    type: "text",
  },
  {
    name: "passport_data",
    label: "Паспортные данные",
    placeholder: "Паспортные данные",
    type: "text",
  },
  {
    name: "registration_address",
    label: "Адрес регистрации",
    placeholder: "Адрес регистрации",
    type: "text",
  },
  {
    name: "living_address",
    label: "Адрес проживания",
    placeholder: "Адрес проживания",
    type: "text",
  },
  {
    name: "email",
    label: "Почта",
    placeholder: "Почта",
    type: "text",
  },
  {
    name: "requisites",
    label: "Реквизиты",
    placeholder: "Реквизиты",
    type: "text",
  },
  {
    name: "npd_reference",
    label: "Справка НПД",
    placeholder: "Справка НПД",
    type: "text",
  },
  {
    name: "status",
    label: "Статус",
    placeholder: "Выберите статус",
    type: "select",
    options: [{ value: "SPECIALIST", label: "Специалист" }],
  },
];

const competenciesFields = [
  {
    name: "text",
    label: "Компетенции",
    placeholder: "Компетенции",
    type: "text",
  },
];

const statusTranslations: { [key: string]: string } = {
  SPECIALIST: "Специалист",
};

const statusColors: { [key: string]: string } = {
  SPECIALIST: "#8BC8EB",
};

const SpecialistTablePage: React.FC = () => {
  useLogout();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shouldAdd = searchParams.get("add") === "true";

  const [activeAllIcon, setActiveAllIcon] = useState(icon_all);
  const [activePlusIcon, setActivePlusIcon] = useState(icon_plus);
  const [activeSearchIcon, setActiveSearchIcon] = useState(icon_search);
  const [activeDeleteIcon, setActiveDeleteIcon] = useState(icon_delete);
  const [activeIdEditIcon, setActiveIdEditIcon] = useState(0);

  const [projects, setProjects] = useState<User[]>([]);
  const [isModalOpen, setModalOpen] = useState(shouldAdd);
  const [currentProject, setCurrentProject] = useState<User | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const sumPage = 4;
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [searchText, setSearchText] = useState<string>("");
  const [breadcrumbs, setBreadcrumbs] = useState<
    { name: string; path: string }[]
  >([]);
  const [visibleFields, setVisibleFields] = useState<string[]>(
    SpecialistFields.map((field) => field.name)
  );
  const [visibleFieldsCompetencies, setVisibleFieldsCcompetencies] = useState<
    string[]
  >(competenciesFields.map((field) => field.name));

  const debouncedSearchText = useDebounce(searchText);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedStage, setSelectedStage] = useState<string>("ALL");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const fetchedProjects = await fetchSpecialist(
          selectedStage,
          debouncedSearchText,
          sortField,
          sortOrder
        );

        console.log("Фильтрация по стадии:", selectedStage);
        console.log("Проекты после фильтрации:", fetchedProjects);

        setProjects(fetchedProjects);
      } catch (err) {
        console.error("Ошибка при загрузке проектов:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [debouncedSearchText, sortField, sortOrder]);

  useEffect(() => {
    setModalOpen(shouldAdd);
  }, [shouldAdd]);

  useEffect(() => {
    if (currentProject) {
      setBreadcrumbs([
        { name: "Панель администратора", path: "/main-admin-panel" },
        { name: "Специалисты", path: "/specialist" },
        { name: getFullName(currentProject), path: "" },
      ]);
    } else if (shouldAdd) {
      setBreadcrumbs([
        { name: "Панель администратора", path: "/main-admin-panel" },
        { name: "Специалисты", path: "/specialist" },
        { name: "Добавление нового проекта", path: "" },
      ]);
    } else {
      setBreadcrumbs([
        { name: "Панель администратора", path: "/main-admin-panel" },
        { name: "Специалисты", path: "/specialist" },
      ]);
    }
  }, [currentProject, shouldAdd]);

  const getFullName = (user: User): string => {
    return [user.firstName, user.middleName, user.lastName]
      .filter(Boolean)
      .join(" ");
  };
  const handleOpenModal = (project: User | null) => {
    console.log("АААДанные проекта до изменений:", project);

    if (project && project.competencies && project.competencies.length > 0) {
      const firstInternship = project.competencies[0];

      const text = firstInternship.text || "";

      const projectWithDetails = {
        ...project,
        text: text,
      };

      console.log("АААДанные проекта после изменений:", projectWithDetails);

      setCurrentProject(projectWithDetails);
    } else {
      setCurrentProject(project);
    }

    setModalOpen(true);
  };
  /*   const handleOpenModal = (project: User | null) => {
    setCurrentProject(project);
    setModalOpen(true);
  }; */

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentProject(null);
  };
  const handleSubmit = async (
    projectData: Partial<User> & {
      text?: string;
    }
  ) => {
    try {
      console.log("Данные проекта для отправки:", projectData);
  
      // Если есть текущий проект, обновляем его
      if (currentProject) {
        try {
          const updatedProject = await updateUser(
            currentProject.id!,
            projectData
          );
  
          // Обработка текста компетенций
          const competencyTexts = projectData.text
            ? projectData.text.split(",").map((text) => text.trim())
            : [];
  
          // Получаем существующие компетенции
          const existingCompetencies = await fetchCompetency();
  
          for (const text of competencyTexts) {
            const existingCompetency = existingCompetencies.find(
              (comp) => comp.text === text
            );
  
            if (existingCompetency) {
              // Если компетенция существует, связываем с проектом
              console.log(
                `Связывание существующей компетенции с ID: ${existingCompetency.id}`
              );
              await createCompetencies(updatedProject.id!, [
                { id: existingCompetency.id, text },
              ]);
            } else if (text) {
              // Если компетенция новая, создаём её
              const competencyData: Competency = { text };
              console.log("Создание новой компетенции:", competencyData);
              await createCompetencies(updatedProject.id!, [competencyData]);
            }
          }
  
          setProjects((prevProjects) =>
            prevProjects.map((p) =>
              p.id === currentProject.id ? updatedProject : p
            )
          );
        } catch (err) {
          console.error("Ошибка при обновлении пользователя или стажировок:", err);
        } finally {
          handleCloseModal();
        }
      } else {
        // Если проекта нет, создаем новый проект
        try {
          const { text, ...cleanedProjectData } = projectData;
  
          const newProject = await createUser({
            ...cleanedProjectData,
            status: "SPECIALIST", // Устанавливаем статус для нового проекта
          } as User);
  
          console.log("Новый пользователь создан:", newProject);
  
          // Обработка текста компетенций
          const competencyTexts = text
            ? text.split(",").map((text) => text.trim())
            : [];
  
          // Получаем существующие компетенции
          const existingCompetencies = await fetchCompetency();
  
          for (const text of competencyTexts) {
            const existingCompetency = existingCompetencies.find(
              (comp) => comp.text === text
            );
  
            if (existingCompetency) {
              console.log(
                `Связывание существующей компетенции с ID: ${existingCompetency.id}`
              );
              await createCompetencies(newProject.id!, [
                { id: existingCompetency.id, text },
              ]);
            } else if (text) {
              const competencyData: Competency = { text };
              console.log("Создание новой компетенции:", competencyData);
              await createCompetencies(newProject.id!, [competencyData]);
            }
          }
  
          setProjects((prevProjects) => [...prevProjects, newProject]);
          handleCloseModal();
        } catch (err) {
          console.error("Ошибка при создании пользователя и стажировок:", err);
        }
      }
    } catch (err) {
      console.error("Ошибка при обработке данных проекта:", err);
    }
  };
  

  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);

  const handleCheckboxChange = (id: number) => {
    setSelectedProjects((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((i) => i !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setSelectedProjects(checked ? projects.map((project) => project.id!) : []);
  };

  const handleBulkDelete = async () => {
    try {
      for (const id of selectedProjects) {
        await deleteUser(id);
      }
      setProjects((prevProjects) =>
        prevProjects.filter((p) => !selectedProjects.includes(p.id!))
      );
      setSelectedProjects([]);
    } catch (err) {
      console.error("Ошибка при удалении проектов:", err);
    }
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleSort = (fieldName: string) => {
    if (sortField === fieldName) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortField(fieldName);
      setSortOrder("asc");
    }
  };
  const sortedProjects = projects.slice().sort((a, b) => {
    if (sortField) {
      const aValue = a[sortField as keyof User] as unknown as string;
      const bValue = b[sortField as keyof User] as unknown as string;

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return sortOrder === "asc"
          ? aValue > bValue
            ? 1
            : -1
          : aValue < bValue
            ? 1
            : -1;
      }
    }
    return 0;
  });

  const lastItem = currentPage * sumPage;
  const firstItem = lastItem - sumPage;
  const currentItems = sortedProjects.slice(firstItem, lastItem);

  const [expandedCells, setExpandedCells] = useState<Set<string>>(new Set());

  const handleCellClick = (id: number, fieldName: string) => {
    const cellKey = `${id}_${fieldName}`;
    setExpandedCells((prevExpandedCells: Set<string>) => {
      const newExpandedCells = new Set(prevExpandedCells);
      if (newExpandedCells.has(cellKey)) {
        newExpandedCells.delete(cellKey);
      } else {
        newExpandedCells.add(cellKey);
      }
      return newExpandedCells;
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const handleToggleField = (fieldName: string) => {
    const isInternField = SpecialistFields.some(
      (field) => field.name === fieldName
    );
    const isCompetencyField = competenciesFields.some(
      (field) => field.name === fieldName
    );

    if (isInternField) {
      setVisibleFields((prevFields) =>
        prevFields.includes(fieldName)
          ? prevFields.filter((field) => field !== fieldName)
          : [...prevFields, fieldName]
      );
    }

    if (isCompetencyField) {
      setVisibleFieldsCcompetencies((prevFields) =>
        prevFields.includes(fieldName)
          ? prevFields.filter((field) => field !== fieldName)
          : [...prevFields, fieldName]
      );
    }
  };
  const handleStageChange = (value: string) => {
    setSelectedStage(value);
    setCurrentPage(1);
  };
  const allFields = [...SpecialistFields, ...competenciesFields];
  const allVisibleFields = [...visibleFields, ...visibleFieldsCompetencies];
  return (
    <div className="interns-table-page">
      <AdminBreadcrumbs breadcrumbs={breadcrumbs} />
      <div className="control-panel">
        <p className="control-panel_title">Специалисты</p>
        {!isModalOpen && (
          <>
            <div className="left_container">
              <FieldSelector
                fields={allFields}
                visibleFields={allVisibleFields}
                onToggleField={handleToggleField}
              />
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Найти"
                  value={searchText}
                  onChange={handleSearchChange}
                  className="search-input"
                />
                <img
                  src={activeSearchIcon}
                  alt="Искать"
                  className="search-icon"
                  onMouseEnter={() => {
                    setActiveSearchIcon(icon_search_active);
                  }}
                  onMouseLeave={() => {
                    setActiveSearchIcon(icon_search);
                  }}
                />
              </div>
            </div>
          </>
        )}
        {!currentProject && (
          <div
            className={`addButton_position ${isModalOpen ? "addButton_largeOffset" : ""}`}
          >
            <div className="addButton_position_content">
              <button
                className={isModalOpen ? "inactive" : "active"}
                onClick={handleCloseModal}
                onMouseEnter={() => {
                  setActiveAllIcon(icon_all_active);
                }}
                onMouseLeave={() => {
                  setActiveAllIcon(icon_all);
                }}
              >
                <img src={activeAllIcon} alt="Все" />
                Все
              </button>
              <button
                className={isModalOpen ? "active" : "inactive"}
                onClick={() => handleOpenModal(null)}
                onMouseEnter={() => {
                  setActivePlusIcon(icon_plus_active);
                }}
                onMouseLeave={() => {
                  setActivePlusIcon(icon_plus);
                }}
              >
                <img src={activePlusIcon} alt="Добавить" />
                Добавить
              </button>
            </div>
          </div>
        )}
      </div>
      {isModalOpen ? (
        <div className="modal-background">
          <div className="modal-content">
            <UniversalForm
              item={currentProject}
              onSubmit={handleSubmit}
              fields={allFields}
            />
            {/* <button onClick={handleCloseModal}>Закрыть</button> */}
          </div>
        </div>
      ) : (
        <div>
          <div className="all_delete">
            <button
              className="deleteButton"
              onClick={handleBulkDelete}
              disabled={selectedProjects.length === 0}
              onMouseEnter={() => {
                selectedProjects.length > 0 &&
                  setActiveDeleteIcon(icon_delete_active);
              }}
              onMouseLeave={() => {
                selectedProjects.length > 0 && setActiveDeleteIcon(icon_delete);
              }}
            >
              <img src={activeDeleteIcon} alt="Удалить" />
            </button>
          </div>
          {isLoading ? (
            <div
              style={{
                height: 400,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2 style={{ color: "#656468" }}>Нет данных</h2>
            </div>
          ) : (
            <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th className="action_delete">
                    <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={
                        selectedProjects.length === projects.length &&
                        projects.length > 0
                      }
                    />
                  </th>
                  <th className="action"></th>
                  {visibleFields.includes("id") && (
                    <th
                      key="id"
                      onClick={() => handleSort("id")}
                      className={`sortable ${
                        sortField === "id"
                          ? sortOrder === "asc"
                            ? "asc"
                            : "desc"
                          : ""
                      }`}
                    >
                      <span className="header-label">
                        ID
                        <span className="sort-icons">
                          {sortField === "id" && sortOrder === "asc" && (
                            <BsChevronUp className="sort-icon active" />
                          )}
                          {sortField === "id" && sortOrder === "desc" && (
                            <BsChevronDown className="sort-icon active" />
                          )}
                          {!(sortField === "id") && (
                            <BsChevronDown className="sort-icon default" />
                          )}
                        </span>
                      </span>
                    </th>
                  )}
                  {SpecialistFields.map((field) =>
                    visibleFields.includes(field.name) &&
                    ["lastName"].includes(field.name) ? (
                      <th
                        key={field.name}
                        onClick={() => handleSort(field.name)}
                        className={`sortable ${
                          sortField === field.name
                            ? sortOrder === "asc"
                              ? "asc"
                              : "desc"
                            : ""
                        }`}
                      >
                        <span className="header-label">
                          {field.name === "lastName" ? "ФИО" : field.label}
                          <span className="sort-icons">
                            {sortField === field.name &&
                              sortOrder === "asc" && (
                                <BsChevronUp className="sort-icon active" />
                              )}
                            {sortField === field.name &&
                              sortOrder === "desc" && (
                                <BsChevronDown className="sort-icon active" />
                              )}
                            {!(sortField === field.name) && (
                              <BsChevronDown className="sort-icon default" />
                            )}
                          </span>
                        </span>
                      </th>
                    ) : null
                  )}
                  {SpecialistFields.map((field) =>
                    visibleFields.includes(field.name) &&
                    field.name === "status" ? (
                      <th
                        key={field.name}
                        onClick={() => handleSort(field.name)}
                        className={`sortable ${
                          sortField === field.name
                            ? sortOrder === "asc"
                              ? "asc"
                              : "desc"
                            : ""
                        }`}
                      >
                        <span className="header-label">
                          {field.label}
                          <span className="sort-icons">
                            {sortField === field.name &&
                              sortOrder === "asc" && (
                                <BsChevronUp className="sort-icon active" />
                              )}
                            {sortField === field.name &&
                              sortOrder === "desc" && (
                                <BsChevronDown className="sort-icon active" />
                              )}
                            {!(sortField === field.name) && (
                              <BsChevronDown className="sort-icon default" />
                            )}
                          </span>
                        </span>
                      </th>
                    ) : null
                  )}
                  {competenciesFields.map((field) =>
                    visibleFieldsCompetencies.includes(field.name) ? (
                      <th
                        key={field.name}
                        onClick={() => handleSort(field.name)}
                        className={`sortable ${
                          sortField === field.name
                            ? sortOrder === "asc"
                              ? "asc"
                              : "desc"
                            : ""
                        }`}
                      >
                        <span className="header-label">
                          {field.label}
                          <span className="sort-icons">
                            {sortField === field.name &&
                              sortOrder === "asc" && (
                                <BsChevronUp className="sort-icon active" />
                              )}
                            {sortField === field.name &&
                              sortOrder === "desc" && (
                                <BsChevronDown className="sort-icon active" />
                              )}
                            {!(sortField === field.name) && (
                              <BsChevronDown className="sort-icon default" />
                            )}
                          </span>
                        </span>
                      </th>
                    ) : null
                  )}
                  {SpecialistFields.map(
                    (field) =>
                      visibleFields.includes(field.name) &&
                      ![
                        "id",
                        "firstName",
                        "lastName",
                        "middleName",
                        "status",
                      ].includes(field.name) && (
                        <th
                          key={field.name}
                          onClick={() => handleSort(field.name)}
                          className={`sortable ${
                            sortField === field.name
                              ? sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : ""
                          }`}
                        >
                          <span className="header-label">
                            {field.label}
                            <span className="sort-icons">
                              {sortField === field.name &&
                                sortOrder === "asc" && (
                                  <BsChevronUp className="sort-icon active" />
                                )}
                              {sortField === field.name &&
                                sortOrder === "desc" && (
                                  <BsChevronDown className="sort-icon active" />
                                )}
                              {!(sortField === field.name) && (
                                <BsChevronDown className="sort-icon default" />
                              )}
                            </span>
                          </span>
                        </th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {currentItems.map(
                  (project) =>
                    project &&
                    project.id !== undefined && (
                      <tr key={project.id}>
                        <td className="action_delete">
                          <input
                            type="checkbox"
                            checked={selectedProjects.includes(project.id!)}
                            onChange={() => handleCheckboxChange(project.id!)}
                          />
                        </td>
                        <td className="action">
                          <img
                            src={
                              activeIdEditIcon === project.id
                                ? icon_edit_active
                                : icon_edit
                            }
                            alt="Редактировать"
                            className="edit_icon"
                            onClick={() => handleOpenModal(project)}
                            style={{ cursor: "pointer", paddingLeft: "10px" }}
                            onMouseEnter={() => {
                              setActiveIdEditIcon(project.id);
                            }}
                            onMouseLeave={() => {
                              setActiveIdEditIcon(0);
                            }}
                          />
                        </td>
                        {visibleFields.includes("id") && (
                          <td
                            key={`${project.id}_id`}
                            onClick={() => handleCellClick(project.id!, "id")}
                            className={`table-cell ${
                              expandedCells.has(`${project.id}_id`)
                                ? "expanded"
                                : ""
                            }`}
                          >
                            {project.id}
                          </td>
                        )}
                        {SpecialistFields.map(
                          (field) =>
                            visibleFields.includes(field.name) &&
                            ["lastName"].includes(field.name) && (
                              <td
                                key={`${project.id}_${field.name}`}
                                onClick={() =>
                                  handleCellClick(project.id!, field.name)
                                }
                                className={`table-cell ${
                                  expandedCells.has(
                                    `${project.id}_${field.name}`
                                  )
                                    ? "expanded"
                                    : ""
                                }`}
                              >
                                {field.name === "lastName" && project.lastName
                                  ? `${project.firstName || ""} ${project.lastName || ""} ${project.middleName || ""}`
                                  : project[
                                      field.name as keyof User
                                    ]?.toString() || ""}
                              </td>
                            )
                        )}

                        {visibleFields.includes("status") && (
                          <td
                            key={`${project.id}_status`}
                            onClick={() =>
                              handleCellClick(project.id!, "status")
                            }
                            className={`table-cell ${
                              expandedCells.has(`${project.id}_status`)
                                ? "expanded"
                                : ""
                            }`}
                          >
                            <span
                              className="status-content"
                              style={{
                                backgroundColor:
                                  statusColors[
                                    project.status as keyof typeof statusColors
                                  ] || "#000",
                              }}
                            >
                              {statusTranslations[
                                project.status as keyof typeof statusTranslations
                              ] || project.status}
                            </span>
                          </td>
                        )}
                        {competenciesFields.map((field) =>
                          visibleFieldsCompetencies.includes(field.name) ? (
                            <td
                              key={field.name}
                              onClick={() =>
                                handleCellClick(project.id!, field.name)
                              }
                              className={
                                expandedCells.has(`${project.id}_${field.name}`)
                                  ? "expanded"
                                  : ""
                              }
                            >
                              {field.name === "text" &&
                              project.competencies &&
                              project.competencies.length > 0 ? (
                                <ul>
                                  {project.competencies.map((competency) => (
                                    <li key={competency.id}>
                                      {competency.text}
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                project[field.name as keyof User]?.toString() ||
                                ""
                              )}
                            </td>
                          ) : null
                        )}
                        {SpecialistFields.map(
                          (field) =>
                            visibleFields.includes(field.name) &&
                            ![
                              "id",
                              "firstName",
                              "lastName",
                              "middleName",
                              "status",
                            ].includes(field.name) && (
                              <td
                                key={`${project.id}_${field.name}`}
                                onClick={() =>
                                  handleCellClick(project.id!, field.name)
                                }
                                className={`table-cell ${
                                  expandedCells.has(
                                    `${project.id}_${field.name}`
                                  )
                                    ? "expanded"
                                    : ""
                                }`}
                              >
                                {field.name === "start_date" ||
                                field.name === "end_date"
                                  ? typeof project[field.name as keyof User] ===
                                    "string"
                                    ? new Date(
                                        project[
                                          field.name as keyof User
                                        ] as string
                                      ).toLocaleDateString()
                                    : ""
                                  : project[
                                      field.name as keyof User
                                    ]?.toString() || ""}
                              </td>
                            )
                        )}
                      </tr>
                    )
                )}
              </tbody>
            </table>
            </div>
          )}
          <Pagination
            currentPage={currentPage}
            itemsPerPage={sumPage}
            totalItems={projects.length}
            paginate={paginate}
          />
        </div>
      )}
    </div>
  );
};

export default SpecialistTablePage;
