import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchTasks,
  createTask,
  updateTask,
  deleteTask,
  Task,
  fetchProjectTitleByName,
  fetchTasksByProjectId,
} from "./../api/projectTasks/TaskApi";
import UniversalForm from "../components/UniversalForm/UniversalForm";
import Pagination from "../components/Pagination/Pagination";
import Snackbar, { SnackbarType } from "../components/Snackbar/Snackbar";

import icon_delete from "../images/icons/icon_delete.svg";
import icon_delete_active from "../images/icons/icon_delete_hover.svg";
import icon_all from "../images/icons/icon_all.svg";
import icon_all_active from "../images/icons/all_icon_hover.svg";
import icon_plus from "../images/icons/icon_plus.svg";
import icon_plus_active from "../images/icons/icon_plus_hover.svg";
import icon_edit from "../images/icons/icon_edit.svg";
import icon_edit_active from "../images/icons/icon_edit_hover.svg";
import icon_search from "../images/icons/icon_search.svg";
import icon_search_active from "../images/icons/icon_search_hover.svg";
import AdminBreadcrumbs from "../components/AdminBreadcrumbs/AdminBreadcrumbs";
import FieldSelector from "../components/FieldSelector/FieldSelector";

import { BsChevronUp, BsChevronDown } from "react-icons/bs";

import "./styles/Table.css";
import { RiseLoader } from "react-spinners";
import { useLogout } from "../helpers/hooks/useLogout";
import { useDebounce } from "../helpers/hooks/useDebounce";

const TaskFields = [
  {
    name: "title",
    label: "Задача",
    placeholder: "Задача",
    type: "text",
  },
  {
    name: "status",
    label: "Статус",
    placeholder: "Выберите статус",
    type: "select",
    options: [
      { value: "COMPLETED", label: "Завершен" },
      { value: "INPROGRESS", label: "В процессе" },
      { value: "NOTSTARTED", label: "Не начат" },
      { value: "VERIFICATION", label: "Проверка" },
      { value: "BLOCKED", label: "Отмена" },
    ],
  },
  {
    name: "project_title",
    label: "Наименование проекта",
    placeholder: "Наименование проекта",
    type: "text",
  },
  {
    name: "start_date",
    label: "Дата начала",
    placeholder: "Дата начала",
    type: "text",
  },
  {
    name: "end_date",
    label: "Дата окончания",
    placeholder: "Дата окончания",
    type: "text",
  },
  {
    name: "hours",
    label: "Время (часы)",
    placeholder: "Время (часы)",
    type: "text",
  },
  {
    name: "comment",
    label: "Комментарий",
    placeholder: "Комментарий",
    type: "text",
  },
];

const statusTranslations: { [key: string]: string } = {
  COMPLETED: "Завершен",
  INPROGRESS: "В процессе",
  NOTSTARTED: "Не начато",
  VERIFICATION: "Проверка",
  BLOCKED: "Отмена",
};

const statusColors: { [key: string]: string } = {
  COMPLETED: "#8beba0",
  INPROGRESS: "#de8beb",
  NOTSTARTED: "#8bc8eb",
  VERIFICATION: "#ebdc8b",
  BLOCKED: "#EB9C8B",
};

const TaskTablePage: React.FC = () => {
  useLogout();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shouldAdd = searchParams.get("add") === "true";
  const isEditing = searchParams.get("edit") === "true";

  const [activeAllIcon, setActiveAllIcon] = useState(icon_all);
  const [activePlusIcon, setActivePlusIcon] = useState(icon_plus);
  const [activeSearchIcon, setActiveSearchIcon] = useState(icon_search);
  const [activeDeleteIcon, setActiveDeleteIcon] = useState(icon_delete);
  const [activeIdEditIcon, setActiveIdEditIcon] = useState(0);

  const [tasks, setTasks] = useState<Task[]>([]);
  const [isModalOpen, setModalOpen] = useState(shouldAdd);
  const [currentTask, setCurrentTask] = useState<Task | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const sumPage = 5;
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [searchText, setSearchText] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [breadcrumbs, setBreadcrumbs] = useState<
    { name: string; path: string }[]
  >([]);
  const [snackbar, setSnackbar] = useState<{
    type: SnackbarType;
    visible: boolean;
  } | null>(null);
  const [visibleFields, setVisibleFields] = useState<string[]>(
    TaskFields.map((field) => field.name)
  );
  const projectId = searchParams.get("projectId");
  const navigate = useNavigate();

  const debouncedSearchText = useDebounce(searchText);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userRole = localStorage.getItem("user_role");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        let fetchedTasks;
        if (userRole === "CUSTOMER" && projectId) {
          fetchedTasks = await fetchTasksByProjectId(
            projectId,
            undefined,
            sortOrder === "desc" ? "desc" : undefined,
            debouncedSearchText
          );
        } else {
          fetchedTasks = await fetchTasks(
            undefined,
            undefined,
            undefined,
            undefined,
            sortOrder === "desc" ? "desc" : undefined,
            debouncedSearchText
          );
        }
        setTasks(fetchedTasks);
        console.log("Загруженные задачи:", fetchedTasks);
      } catch (err) {
        console.error("Ошибка при загрузке задач:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sortField, sortOrder, debouncedSearchText, projectId, userRole]);

  useEffect(() => {
    setModalOpen(shouldAdd);
  }, [shouldAdd]);

  useEffect(() => {
    const projectTitle = tasks.length > 0 ? tasks[0].project?.title : "Проект";

    let newBreadcrumbs;

    if (userRole === "CUSTOMER") {
      newBreadcrumbs = [
        { name: "Проекты", path: "/project_view" },
        { name: projectTitle || "", path: "/project_view" },
        { name: "Задачи по проекту", path: location.pathname },
      ];
    } else {
      newBreadcrumbs = [{ name: "Задачи", path: location.pathname }];
    }

    if (isEditing && currentTask) {
      newBreadcrumbs.push({ name: currentTask.title, path: "" });
    }

    setBreadcrumbs(newBreadcrumbs);
  }, [tasks, isEditing, currentTask, location.pathname, projectId, userRole]);

  const handleOpenModal = (task: Task | null, mode: "edit" | "add") => {
    setCurrentTask(task);
    setModalOpen(true);
    if (mode === "edit") {
      navigate(`${location.pathname}?projectId=${projectId}&edit=true`);
    } else {
      navigate(`${location.pathname}?projectId=${projectId}&add=true`);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentTask(null);
    navigate(`${location.pathname}?projectId=${projectId}`);
  };

  const userIdStr = localStorage.getItem("userId");

  const handleSubmit = async (taskData: Partial<Task>) => {
    try {
      const projectId = await fetchProjectTitleByName(
        taskData.project_title as string
      );
      if (projectId) {
        taskData.project_id = projectId;
        delete taskData.project_title;
      } else {
        throw new Error("Проект не найден");
      }

      if (taskData.hours !== undefined) {
        taskData.hours = Number(taskData.hours);
        if (isNaN(taskData.hours)) {
          throw new Error("Поле 'hours' должно быть числом");
        }
      }

      console.log("Данные задачи для отправки:", taskData);
      if (currentTask) {
        if (userIdStr) {
          const userId = parseInt(userIdStr, 10);
          const updatedTask = await updateTask(
            currentTask.id!,
            userId,
            taskData
          );
          setTasks((prevTasks) =>
            prevTasks.map((t) => (t.id === currentTask.id ? updatedTask : t))
          );
          showSnackbar("successChange");
        } else {
          throw new Error("Ошибка: ID пользователя не найден");
        }
      } else {
        if (userIdStr) {
          const userId = parseInt(userIdStr, 10);
          const newTask = await createTask(taskData as Task, userId);
          setTasks((prevTasks) => [...prevTasks, newTask]);
          showSnackbar("successAdd");
        } else {
          throw new Error("Ошибка: ID пользователя не найден");
        }
      }
      handleCloseModal();
    } catch (err) {
      console.error("Ошибка при создании/обновлении задачи:", err);
      showSnackbar("errorSendingData");
    }
  };

  const showSnackbar = (type: SnackbarType) => {
    setSnackbar({ type, visible: true });
  };

  const [selectedTasks, setSelectedTasks] = useState<number[]>([]);

  const handleCheckboxChange = (id: number) => {
    setSelectedTasks((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((i) => i !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setSelectedTasks(checked ? tasks.map((task) => task.id!) : []);
  };

  const handleBulkDelete = async () => {
    try {
      for (const id of selectedTasks) {
        await deleteTask(id);
      }
      setTasks((prevTasks) =>
        prevTasks.filter((t) => !selectedTasks.includes(t.id!))
      );
      setSelectedTasks([]);
    } catch (err) {
      console.error("Ошибка при удалении задач:", err);
    }
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleSort = (fieldName: string) => {
    if (sortField === fieldName) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortField(fieldName);
      setSortOrder("asc");
    }
  };

  const sortedTasks = tasks.slice().sort((a, b) => {
    if (sortField) {
      const aValue = a[sortField as keyof Task] as unknown as string;
      const bValue = b[sortField as keyof Task] as unknown as string;

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return sortOrder === "asc"
          ? aValue > bValue
            ? 1
            : -1
          : aValue < bValue
            ? 1
            : -1;
      }
    }
    return 0;
  });

  const lastItem = currentPage * sumPage;
  const firstItem = lastItem - sumPage;
  const currentItems = sortedTasks.slice(firstItem, lastItem);

  const [expandedCells, setExpandedCells] = useState<Set<string>>(new Set());

  const handleCellClick = (id: number, fieldName: string) => {
    const cellKey = `${id}_${fieldName}`;
    setExpandedCells((prevExpandedCells: Set<string>) => {
      const newExpandedCells = new Set(prevExpandedCells);
      if (newExpandedCells.has(cellKey)) {
        newExpandedCells.delete(cellKey);
      } else {
        newExpandedCells.add(cellKey);
      }
      return newExpandedCells;
    });
  };

  const handleSearchClick = () => {
    setSearchQuery(searchText);
    setCurrentPage(1);
  };

  const handleToggleField = (fieldName: string) => {
    setVisibleFields((prevFields) =>
      prevFields.includes(fieldName)
        ? prevFields.filter((field) => field !== fieldName)
        : [...prevFields, fieldName]
    );
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString("ru-RU");
  };

  const calculateTotalHours = (tasks: Task[]): number => {
    return tasks.reduce((total, task) => total + (task.hours || 0), 0);
  };

  const totalHours = calculateTotalHours(tasks);

  return (
    <div className="interns-table-page">
      <AdminBreadcrumbs breadcrumbs={breadcrumbs} />
      <div className="control-panel">
        <p className="control-panel_title">
          {userRole === "CUSTOMER" ? "Задачи по проекту" : "Задачи"}
        </p>
        {!isModalOpen && (
          <>
            <div className="left_container">
              <FieldSelector
                fields={TaskFields}
                visibleFields={visibleFields}
                onToggleField={handleToggleField}
              />
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Найти"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className="search-input"
                />
                <img
                  src={activeSearchIcon}
                  alt="Искать"
                  className="search-icon"
                  onClick={handleSearchClick}
                  style={{ cursor: "pointer" }}
                  onMouseEnter={() => {
                    setActiveSearchIcon(icon_search_active);
                  }}
                  onMouseLeave={() => {
                    setActiveSearchIcon(icon_search);
                  }}
                />
              </div>
            </div>
          </>
        )}
        {!currentTask && userRole !== "CUSTOMER" && (
        <div className={`addButton_position ${isModalOpen ? "addButton_largeOffset" : ""}`}>
          <div className="addButton_position_content">
            <button
              className={isModalOpen ? "inactive" : "active"}
              onClick={handleCloseModal}
              onMouseEnter={() => {
                setActiveAllIcon(icon_all_active);
              }}
              onMouseLeave={() => {
                setActiveAllIcon(icon_all);
              }}
            >
              <img src={activeAllIcon} alt="Все" />
              Все
            </button>
            <button
              className={isModalOpen ? "active" : "inactive"}
              onClick={() => handleOpenModal(null, "add")}
              onMouseEnter={() => {
                setActivePlusIcon(icon_plus_active);
              }}
              onMouseLeave={() => {
                setActivePlusIcon(icon_plus);
              }}
            >
              <img src={activePlusIcon} alt="Добавить" />
              Добавить
            </button>
          </div>
        </div>
        )}
      </div>
      {isModalOpen ? (
        <div className="modal-background">
          <div className="modal-content">
            <UniversalForm
              item={currentTask}
              onSubmit={handleSubmit}
              fields={TaskFields}
            />
            <button onClick={handleCloseModal}>Закрыть</button>
          </div>
        </div>
      ) : (
        <>
          {/* {userRole !== "CUSTOMER" && ( */}
          <div className="all_delete">
            <button
              className="deleteButton"
              onClick={handleBulkDelete}
              disabled={selectedTasks.length === 0}
              onMouseEnter={() => {
                selectedTasks.length > 0 &&
                  setActiveDeleteIcon(icon_delete_active);
              }}
              onMouseLeave={() => {
                selectedTasks.length > 0 && setActiveDeleteIcon(icon_delete);
              }}
            >
              <img src={activeDeleteIcon} alt="Удалить" />
            </button>
          </div>
          {/* )} */}
          {isLoading ? (
            <div
              style={{
                height: 400,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RiseLoader loading={isLoading} size={40} color="red" />
            </div>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  {/* {userRole !== "CUSTOMER" && ( */}
                  <>
                    <th className="action">
                      <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={
                          selectedTasks.length > 0 &&
                          selectedTasks.length === tasks.length
                        }
                      />
                    </th>
                    <th className="action"></th>
                  </>
                  {/* )} */}
                  {TaskFields.map(
                    (field) =>
                      visibleFields.includes(field.name) && (
                        <th
                          key={field.name}
                          onClick={() => handleSort(field.name)}
                          className={`sortable ${
                            sortField === field.name
                              ? sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : ""
                          }`}
                        >
                          <span className="header-label">
                            {field.label}
                            <span className="sort-icons">
                              {sortField === field.name &&
                                sortOrder === "asc" && (
                                  <BsChevronUp className="sort-icon active" />
                                )}
                              {sortField === field.name &&
                                sortOrder === "desc" && (
                                  <BsChevronDown className="sort-icon active" />
                                )}
                              {sortField !== field.name && (
                                <BsChevronDown className="sort-icon default" />
                              )}
                            </span>
                          </span>
                        </th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {currentItems.map((task) => (
                  <tr key={task.id}>
                    {/* {userRole !== "CUSTOMER" && ( */}
                    <>
                      <td className="action">
                        <input
                          type="checkbox"
                          checked={selectedTasks.includes(task.id!)}
                          onChange={() => handleCheckboxChange(task.id!)}
                        />
                      </td>
                      <td className="action">
                        <img
                          src={
                            activeIdEditIcon === task.id
                              ? icon_edit_active
                              : icon_edit
                          }
                          alt="Редактировать"
                          className="edit_icon"
                          onClick={() => handleOpenModal(task, "edit")}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => {
                            setActiveIdEditIcon(task.id || 0);
                          }}
                          onMouseLeave={() => {
                            setActiveIdEditIcon(0);
                          }}
                        />
                      </td>
                    </>
                    {/* )} */}
                    {TaskFields.map(
                      (field) =>
                        visibleFields.includes(field.name) && (
                          <td
                            key={`${task.id}_${field.name}`}
                            onClick={() =>
                              handleCellClick(task.id!, field.name)
                            }
                            className={`table-cell ${
                              expandedCells.has(`${task.id}_${field.name}`)
                                ? "expanded"
                                : ""
                            }`}
                          >
                            {field.name === "status" ? (
                              <span
                                className="status-content"
                                style={{
                                  backgroundColor:
                                    statusColors[task[field.name] as string] ||
                                    "#000",
                                }}
                              >
                                {statusTranslations[
                                  task[field.name] as string
                                ] ||
                                  (task[field.name as keyof Task] as string) ||
                                  ""}
                              </span>
                            ) : field.name === "project_title" ? (
                              task.project?.title || ""
                            ) : field.name === "start_date" ||
                              field.name === "end_date" ? (
                              formatDate(
                                task[field.name as keyof Task] as string
                              )
                            ) : (
                              task[field.name as keyof Task]?.toString() || ""
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <Pagination
            currentPage={currentPage}
            itemsPerPage={sumPage}
            totalItems={tasks.length}
            paginate={paginate}
          />
          {userRole !== "CUSTOMER" && (
            <div className="total-hours">
              <p>
                Сумма часов:
                <span className="border-total-hours">{totalHours}</span>
              </p>
            </div>
          )}
        </>
      )}
      {snackbar && snackbar.visible && (
        <Snackbar type={snackbar.type} onClose={() => setSnackbar(null)} />
      )}
    </div>
  );
};

export default TaskTablePage;
