import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  fetchPractice,
  updateUser,
  deleteUser,
  fetchUserByStatus,
  User,
  createUser,
  createPractice,
  Competency,
  createCompetencies,
  Internship,
  fetchAllInternships,
  fetchCompetency,
  createInternships,
} from "../api/userTasks/userApi";
import UniversalForm from "../components/UniversalForm/UniversalForm";
import Pagination from "../components/Pagination/Pagination";
import icon_delete from "../images/icons/icon_delete.svg";
import icon_delete_active from "../images/icons/icon_delete_hover.svg";
import icon_all from "../images/icons/icon_all.svg";
import icon_all_active from "../images/icons/all_icon_hover.svg";
import icon_plus from "../images/icons/icon_plus.svg";
import icon_plus_active from "../images/icons/icon_plus_hover.svg";
import icon_edit from "../images/icons/icon_edit.svg";
import icon_edit_active from "../images/icons/icon_edit_hover.svg";
import icon_search from "../images/icons/icon_search.svg";
import icon_search_active from "../images/icons/icon_search_hover.svg";
import AdminBreadcrumbs from "../components/AdminBreadcrumbs/AdminBreadcrumbs";
import FieldSelector from "../components/FieldSelector/FieldSelector";
import SelectStage from "../components/SelectStage/SelectStage";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import "./styles/Table.css";
import { RiseLoader } from "react-spinners";
import { useLogout } from "../helpers/hooks/useLogout";
import { useDebounce } from "../helpers/hooks/useDebounce";

const PracticeFields = [
  { name: "id", label: "ID", placeholder: "ID", type: "text" },
  {
    name: "firstName",
    label: "Имя",
    placeholder: "Имя",
    type: "text",
    required: true,
  },
  { name: "lastName", label: "Фамилия", placeholder: "Фамилия", type: "text" },
  {
    name: "middleName",
    label: "Отчество",
    placeholder: "Отчество",
    type: "text",
  },

  { name: "login", label: "Логин", placeholder: "login", type: "text" },

  {
    name: "telegram",
    label: "Telegram",
    placeholder: "Телеграм",
    type: "text",
  },
  {
    name: "phone",
    label: "Телефон",
    placeholder: "Телефон",
    type: "text",
  },
  {
    name: "direction",
    label: "Профиль",
    placeholder: "Профиль",
    type: "text",
  },
  {
    name: "study_group",
    label: "Образование/Группа",
    placeholder: "Вектор развития",
    type: "text",
  },
  {
    name: "portfolio",
    label: "Портфолио",
    placeholder: "Портфолио",
    type: "text",
  },
  {
    name: "plans",
    label: "Вектор развития",
    placeholder: "Вектор развития",
    type: "text",
  },
  {
    name: "note",
    label: "Комментарий",
    placeholder: "Комментарий",
    type: "text",
  },
];
const InternFields = [
  {
    name: "status",
    label: "Статус",
    placeholder: "Выберите статус",
    type: "select",
    options: [
      { value: "SUCCESSCOMPLETED", label: "Завершен" },
      { value: "INPROGRESS", label: "В процессе" },
      { value: "BADCOMPELTED", label: "Не начат" },
    ],
  },

  {
    name: "start_date",
    label: "Начало стажировки",
    placeholder: "Начало стажировки",
    type: "text",
    required: true,
  },
  {
    name: "end_date",
    label: "Конец стажировки",
    placeholder: "Конец стажировки",
    type: "text",
    required: true,
  },
  {
    name: "practice_task",
    label: "Текущее задание",
    placeholder: "Текущее задание",
    type: "text",
  },
];
const competenciesFields = [
  {
    name: "text",
    label: "Компетенции",
    placeholder: "Компетенции",
    type: "text",
  },
];

const statusTranslations: { [key: string]: string } = {
  INPROGRESS: "В процессе",
  SUCCESSCOMPLETED: "Завершено-актив",
  BADCOMPELTED: "Завершено-плохо",
};

const statusColors: { [key: string]: string } = {
  COMPLETED: "#8beba0",
  NOTSTARTED: "#8bc8eb",
  CHECK: "#ebdc8b",
  CANCEL: "#EB9C8B",
  SPECIALIST: "#de8beb",
  INTERNSHIP: "#de8beb",
  INPROGRESS: "#DE8BEB",
  SUCCESSCOMPLETED: "#8BEBA0",
  BADCOMPELTED: "#EB9C8B",
};

const stageOptions = [
  { value: "ALL", label: "Все" },
  { value: "ACTIVE", label: "Активные" },
  { value: "FINISHED", label: "Завершенные" },
];

const TraineeTablePage: React.FC = () => {
  useLogout();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shouldAdd = searchParams.get("add") === "true";

  const [activeAllIcon, setActiveAllIcon] = useState(icon_all);
  const [activePlusIcon, setActivePlusIcon] = useState(icon_plus);
  const [activeSearchIcon, setActiveSearchIcon] = useState(icon_search);
  const [activeDeleteIcon, setActiveDeleteIcon] = useState(icon_delete);
  const [activeIdEditIcon, setActiveIdEditIcon] = useState(0);

  const [projects, setProjects] = useState<User[]>([]);
  const [isModalOpen, setModalOpen] = useState(shouldAdd);
  const [currentProject, setCurrentProject] = useState<User | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const sumPage = 4;
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [searchText, setSearchText] = useState<string>("");
  const [breadcrumbs, setBreadcrumbs] = useState<
    { name: string; path: string }[]
  >([]);
  const [visibleFields, setVisibleFields] = useState<string[]>(
    PracticeFields.map((field) => field.name)
  );
  const [visibleFieldsIntern, setVisibleFieldsIntern] = useState<string[]>(
    InternFields.map((field) => field.name)
  );
  const [visibleFieldsCompetencies, setVisibleFieldsCcompetencies] = useState<
    string[]
  >(competenciesFields.map((field) => field.name));

  const debouncedSearchText = useDebounce(searchText);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedStage, setSelectedStage] = useState<string>("ALL");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedProjects: User[] = await fetchPractice(
          selectedStage,
          searchText,
          sortField,
          sortOrder
        );

        setProjects(fetchedProjects);
      } catch (err) {
        console.error("Ошибка при загрузке проектов:", err);
      }
    };

    fetchData();
  }, [selectedStage, searchText, sortField, sortOrder]);

  useEffect(() => {
    setModalOpen(shouldAdd);
  }, [shouldAdd]);

  useEffect(() => {
    if (currentProject) {
      setBreadcrumbs([
        { name: "Панель администратора", path: "/main-admin-panel" },
        { name: "Практиканты", path: "/trainee" },
        { name: getFullName(currentProject), path: "" },
      ]);
    } else if (shouldAdd) {
      setBreadcrumbs([
        { name: "Панель администратора", path: "/main-admin-panel" },
        { name: "Практиканты", path: "/trainee" },
        { name: "Добавление нового проекта", path: "" },
      ]);
    } else {
      setBreadcrumbs([
        { name: "Панель администратора", path: "/main-admin-panel" },
        { name: "Практиканты", path: "/trainee" },
      ]);
    }
  }, [currentProject, shouldAdd]);

  const getFullName = (user: User): string => {
    return [user.firstName, user.middleName, user.lastName]
      .filter(Boolean)
      .join(" ");
  };

  const handleOpenModal = (project: User | null) => {
    console.log("АААДанные проекта до изменений:", project);

    if (project && project.internships && project.internships.length > 0) {
      const firstInternship = project.internships[0];
      const startDate = firstInternship.start_date
        ? new Date(firstInternship.start_date).toISOString().slice(0, 10)
        : "";
      const endDate = firstInternship.end_date
        ? new Date(firstInternship.end_date).toISOString().slice(0, 10)
        : "";
      const status = firstInternship.status || "";
      const practiceTask = firstInternship.practice_task || "";

      const projectWithDetails = {
        ...project,
        start_date: startDate,
        end_date: endDate,
        status: status,
        practice_task: practiceTask,
      };

      console.log("АААДанные проекта после изменений:", projectWithDetails);

      setCurrentProject(projectWithDetails);
    } else {
      setCurrentProject(project);
    }

    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentProject(null);
  };
  const handleSubmit = async (
    projectData: Partial<User> & {
      start_date?: string;
      end_date?: string;
      status?: string;
      practice_task?: string;
      text?: string;
    }
  ) => {
    try {
      console.log("Данные проекта для отправки:", projectData);

      const hasInternshipData =
        projectData.start_date ||
        projectData.practice_task ||
        projectData.end_date ||
        projectData.status;

      const updatedInternships: Internship[] = hasInternshipData
        ? [
            {
              ...(projectData.internships && projectData.internships.length > 0
                ? projectData.internships[0]
                : {}),
              practice_task: projectData.practice_task || "",
              start_date: projectData.start_date || "",
              end_date: projectData.end_date || "",
              status: projectData.status || "INPROGRESS",
              id:
                projectData.internships && projectData.internships.length > 0
                  ? projectData.internships[0].id
                  : undefined,
              user_id:
                projectData.internships && projectData.internships.length > 0
                  ? projectData.internships[0].user_id
                  : undefined,
            },
          ]
        : projectData.internships || [];

      const updatedProjectData: User = {
        ...currentProject!,
        ...projectData,
        internships: updatedInternships,
      };

      if (currentProject) {
        try {
          const updatedProject = await updateUser(
            currentProject.id!,
            updatedProjectData
          );

          const newInternships = updatedInternships.filter(
            (internship) => internship.id === undefined
          );

          if (newInternships.length > 0) {
            await createPractice(updatedProject.id!, newInternships);
          }

          const competencyTexts = projectData.text
            ? projectData.text.split(",").map((text) => text.trim())
            : [];

          const existingCompetencies = await fetchCompetency();

          for (const text of competencyTexts) {
            const existingCompetency = existingCompetencies.find(
              (comp) => comp.text === text
            );

            if (existingCompetency) {
              console.log(
                `Связывание существующей компетенции с ID: ${existingCompetency.id}`
              );
              await createCompetencies(updatedProject.id!, [
                { id: existingCompetency.id, text },
              ]);
            } else if (text) {
              const competencyData: Competency = { text };
              console.log("Создание новой компетенции:", competencyData);
              await createCompetencies(updatedProject.id!, [competencyData]);
            }
          }

          setProjects((prevProjects) =>
            prevProjects.map((p) =>
              p.id === currentProject.id ? updatedProject : p
            )
          );
        } catch (err) {
          console.error(
            "Ошибка при обновлении пользователя или стажировок:",
            err
          );
        } finally {
          handleCloseModal();
        }
      } else {
        try {
          const {
            start_date,
            end_date,
            status,
            practice_task,
            text,
            ...cleanedProjectData
          } = projectData;

          const newProject = await createUser({
            ...cleanedProjectData,
            practice_task,
            status: "PRACTICE",
          } as User);

          console.log("Новый пользователь создан:", newProject);

          if (hasInternshipData) {
            const internshipsWithUserId = updatedInternships.map(
              (internship) => ({
                ...internship,
                user_id: newProject.id,
                start_date: internship.start_date || "",
                end_date: internship.end_date || "",
                status: internship.status || "INPROGRESS",
                practice_task: internship.practice_task || "",
              })
            );
            console.log(
              "Создание стажировок с user_id:",
              internshipsWithUserId
            );
            await createPractice(newProject.id!, internshipsWithUserId);
          }

          const competencyTexts = text
            ? text.split(",").map((text) => text.trim())
            : [];

          const existingCompetencies = await fetchCompetency(); // Функция для получения всех компетенций

          for (const text of competencyTexts) {
            const existingCompetency = existingCompetencies.find(
              (comp) => comp.text === text
            );

            if (existingCompetency) {
              console.log(
                `Связывание существующей компетенции с ID: ${existingCompetency.id}`
              );
              await createCompetencies(newProject.id!, [
                { id: existingCompetency.id, text },
              ]);
            } else if (text) {
              const competencyData: Competency = { text };
              console.log("Создание новой компетенции:", competencyData);
              await createCompetencies(newProject.id!, [competencyData]);
            }
          }

          setProjects((prevProjects) => [...prevProjects, newProject]);
          handleCloseModal();
        } catch (err) {
          console.error("Ошибка при создании пользователя и стажировок:", err);
        }
      }
    } catch (err) {
      console.error("Ошибка при обработке данных проекта:", err);
    }
  };
  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);

  const handleCheckboxChange = (id: number) => {
    setSelectedProjects((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((i) => i !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setSelectedProjects(checked ? projects.map((project) => project.id!) : []);
  };

  const handleBulkDelete = async () => {
    try {
      for (const id of selectedProjects) {
        await deleteUser(id);
      }
      setProjects((prevProjects) =>
        prevProjects.filter((p) => !selectedProjects.includes(p.id!))
      );
      setSelectedProjects([]);
    } catch (err) {
      console.error("Ошибка при удалении проектов:", err);
    }
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleSort = (fieldName: string) => {
    if (sortField === fieldName) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortField(fieldName);
      setSortOrder("asc");
    }
  };
  const sortedProjects = projects.slice().sort((a, b) => {
    if (sortField) {
      let aValue:
        | string
        | number
        | boolean
        | Internship[]
        | Competency[]
        | undefined;
      let bValue:
        | string
        | number
        | boolean
        | Internship[]
        | Competency[]
        | undefined;

      if (sortField === "start_date" || sortField === "end_date") {
        aValue =
          a.internships && a.internships.length > 0
            ? a.internships[0][sortField as keyof Internship]
            : undefined;
        bValue =
          b.internships && b.internships.length > 0
            ? b.internships[0][sortField as keyof Internship]
            : undefined;
      } else if (sortField === "text") {
        aValue =
          a.competencies && a.competencies.length > 0
            ? a.competencies[0][sortField as keyof Competency]
            : undefined;
        bValue =
          b.competencies && b.competencies.length > 0
            ? b.competencies[0][sortField as keyof Competency]
            : undefined;
      } else {
        aValue = a[sortField as keyof User];
        bValue = b[sortField as keyof User];
      }

      if (aValue === undefined || bValue === undefined) {
        return aValue === undefined ? 1 : -1;
      }

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      }

      return 0;
    }
    return 0;
  });

  const lastItem = currentPage * sumPage;
  const firstItem = lastItem - sumPage;
  const currentItems = sortedProjects.slice(firstItem, lastItem);

  const [expandedCells, setExpandedCells] = useState<Set<string>>(new Set());

  const handleCellClick = (id: number, fieldName: string) => {
    const cellKey = `${id}_${fieldName}`;
    setExpandedCells((prevExpandedCells: Set<string>) => {
      const newExpandedCells = new Set(prevExpandedCells);
      if (newExpandedCells.has(cellKey)) {
        newExpandedCells.delete(cellKey);
      } else {
        newExpandedCells.add(cellKey);
      }
      return newExpandedCells;
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const handleToggleField = (fieldName: string) => {
    const isInternField = PracticeFields.some(
      (field) => field.name === fieldName
    );
    const isCompetencyField = competenciesFields.some(
      (field) => field.name === fieldName
    );

    if (isInternField) {
      setVisibleFields((prevFields) =>
        prevFields.includes(fieldName)
          ? prevFields.filter((field) => field !== fieldName)
          : [...prevFields, fieldName]
      );
    }

    if (isCompetencyField) {
      setVisibleFieldsCcompetencies((prevFields) =>
        prevFields.includes(fieldName)
          ? prevFields.filter((field) => field !== fieldName)
          : [...prevFields, fieldName]
      );
    }
  };
  const handleStageChange = (value: string) => {
    setSelectedStage(value);
    setCurrentPage(1);
  };
  const [internships, setInternships] = useState<Internship[]>([]);
  useEffect(() => {
    const loadInternships = async () => {
      try {
        const data = await fetchAllInternships();
        setInternships(data);
        console.log("Полученные стажировки:", data);
      } catch (error) {
        console.error("Ошибка при получении стажировок:", error);
      }
    };
    loadInternships();
  }, []);
  const allFields = [...PracticeFields, ...InternFields, ...competenciesFields];
  const allVisibleFields = [
    ...visibleFields,
    ...visibleFieldsIntern,
    ...visibleFieldsCompetencies,
  ];
  return (
    <div className="interns-table-page">
      <AdminBreadcrumbs breadcrumbs={breadcrumbs} />
      <div className="control-panel">
        <p className="control-panel_title">Практиканты</p>
        {!isModalOpen && (
          <>
            <div className="control-panel_stage">
              <SelectStage
                options={stageOptions}
                selectedValue={selectedStage}
                onChange={handleStageChange}
              />
            </div>
            <div className="left_container">
              <FieldSelector
                fields={allFields}
                visibleFields={allVisibleFields}
                onToggleField={handleToggleField}
              />
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Найти"
                  value={searchText}
                  onChange={handleSearchChange}
                  className="search-input"
                />
                <img
                  src={activeSearchIcon}
                  alt="Искать"
                  className="search-icon"
                  onMouseEnter={() => {
                    setActiveSearchIcon(icon_search_active);
                  }}
                  onMouseLeave={() => {
                    setActiveSearchIcon(icon_search);
                  }}
                />
              </div>
            </div>
          </>
        )}
        {!currentProject && (
          <div
            className={`addButton_position ${isModalOpen ? "addButton_largeOffset" : ""}`}
          >
            <div className="addButton_position_content">
              <button
                className={isModalOpen ? "inactive" : "active"}
                onClick={handleCloseModal}
                onMouseEnter={() => {
                  setActiveAllIcon(icon_all_active);
                }}
                onMouseLeave={() => {
                  setActiveAllIcon(icon_all);
                }}
              >
                <img src={activeAllIcon} alt="Все" />
                Все
              </button>
              <button
                className={isModalOpen ? "active" : "inactive"}
                onClick={() => handleOpenModal(null)}
                onMouseEnter={() => {
                  setActivePlusIcon(icon_plus_active);
                }}
                onMouseLeave={() => {
                  setActivePlusIcon(icon_plus);
                }}
              >
                <img src={activePlusIcon} alt="Добавить" />
                Добавить
              </button>
            </div>
          </div>
        )}
      </div>
      {isModalOpen ? (
        <div className="modal-background">
          <div className="modal-content">
            <UniversalForm
              item={currentProject}
              onSubmit={handleSubmit}
              fields={allFields}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="all_delete">
            <button
              className="deleteButton"
              onClick={handleBulkDelete}
              disabled={selectedProjects.length === 0}
              onMouseEnter={() => {
                selectedProjects.length > 0 &&
                  setActiveDeleteIcon(icon_delete_active);
              }}
              onMouseLeave={() => {
                selectedProjects.length > 0 && setActiveDeleteIcon(icon_delete);
              }}
            >
              <img src={activeDeleteIcon} alt="Удалить" />
            </button>
          </div>
          {isLoading ? (
            <div
              style={{
                height: 400,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2 style={{ color: "#656468" }}>Нет данных</h2>
            </div>
          ) : (
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <th className="action_delete">
                      <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={
                          selectedProjects.length === projects.length &&
                          projects.length > 0
                        }
                      />
                    </th>
                    <th className="action"></th>
                    {PracticeFields.filter((field) => field.name === "id").map(
                      (field) =>
                        visibleFields.includes(field.name) ? (
                          <th
                            key={field.name}
                            onClick={() => handleSort(field.name)}
                            className={`sortable ${
                              sortField === field.name
                                ? sortOrder === "asc"
                                  ? "asc"
                                  : "desc"
                                : ""
                            }`}
                          >
                            <span className="header-label">
                              {field.label}
                              <span className="sort-icons">
                                {sortField === field.name &&
                                  sortOrder === "asc" && (
                                    <BsChevronUp className="sort-icon active" />
                                  )}
                                {sortField === field.name &&
                                  sortOrder === "desc" && (
                                    <BsChevronDown className="sort-icon active" />
                                  )}
                                {!(sortField === field.name) && (
                                  <BsChevronDown className="sort-icon default" />
                                )}
                              </span>
                            </span>
                          </th>
                        ) : null
                    )}
                    {PracticeFields.map((field) =>
                      visibleFields.includes(field.name) &&
                      ["lastName"].includes(field.name) ? (
                        <th
                          key={field.name}
                          onClick={() => handleSort(field.name)}
                          className={`sortable ${
                            sortField === field.name
                              ? sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : ""
                          }`}
                        >
                          <span className="header-label">
                            {field.name === "lastName" ? "ФИО" : field.label}
                            <span className="sort-icons">
                              {sortField === field.name &&
                                sortOrder === "asc" && (
                                  <BsChevronUp className="sort-icon active" />
                                )}
                              {sortField === field.name &&
                                sortOrder === "desc" && (
                                  <BsChevronDown className="sort-icon active" />
                                )}
                              {!(sortField === field.name) && (
                                <BsChevronDown className="sort-icon default" />
                              )}
                            </span>
                          </span>
                        </th>
                      ) : null
                    )}
                    {InternFields.map((field) =>
                      visibleFieldsIntern.includes(field.name) ||
                      field.name === "status" ? (
                        <th
                          key={field.name}
                          onClick={() => handleSort(field.name)}
                          className={`sortable ${
                            sortField === field.name
                              ? sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : ""
                          }`}
                        >
                          <span className="header-label">
                            {field.label}
                            <span className="sort-icons">
                              {sortField === field.name &&
                                sortOrder === "asc" && (
                                  <BsChevronUp className="sort-icon active" />
                                )}
                              {sortField === field.name &&
                                sortOrder === "desc" && (
                                  <BsChevronDown className="sort-icon active" />
                                )}
                              {!(sortField === field.name) && (
                                <BsChevronDown className="sort-icon default" />
                              )}
                            </span>
                          </span>
                        </th>
                      ) : null
                    )}

                    {competenciesFields.map((field) =>
                      visibleFieldsCompetencies.includes(field.name) ? (
                        <th
                          key={field.name}
                          onClick={() => handleSort(field.name)}
                          className={`sortable ${
                            sortField === field.name
                              ? sortOrder === "asc"
                                ? "asc"
                                : "desc"
                              : ""
                          }`}
                        >
                          <span className="header-label">
                            {field.label}
                            <span className="sort-icons">
                              {sortField === field.name &&
                                sortOrder === "asc" && (
                                  <BsChevronUp className="sort-icon active" />
                                )}
                              {sortField === field.name &&
                                sortOrder === "desc" && (
                                  <BsChevronDown className="sort-icon active" />
                                )}
                              {!(sortField === field.name) && (
                                <BsChevronDown className="sort-icon default" />
                              )}
                            </span>
                          </span>
                        </th>
                      ) : null
                    )}
                    {PracticeFields.map(
                      (field) =>
                        visibleFields.includes(field.name) &&
                        !["id", "firstName", "lastName", "middleName"].includes(
                          field.name
                        ) && (
                          <th
                            key={field.name}
                            onClick={() => handleSort(field.name)}
                            className={`sortable ${
                              sortField === field.name
                                ? sortOrder === "asc"
                                  ? "asc"
                                  : "desc"
                                : ""
                            }`}
                          >
                            <span className="header-label">
                              {field.label}
                              <span className="sort-icons">
                                {sortField === field.name &&
                                  sortOrder === "asc" && (
                                    <BsChevronUp className="sort-icon active" />
                                  )}
                                {sortField === field.name &&
                                  sortOrder === "desc" && (
                                    <BsChevronDown className="sort-icon active" />
                                  )}
                                {!(sortField === field.name) && (
                                  <BsChevronDown className="sort-icon default" />
                                )}
                              </span>
                            </span>
                          </th>
                        )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((project) =>
                    project && project.id !== undefined ? (
                      <tr key={project.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedProjects.includes(project.id!)}
                            onChange={() => handleCheckboxChange(project.id!)}
                          />
                        </td>
                        <td>
                          <img
                            src={
                              activeIdEditIcon === project.id
                                ? icon_edit_active
                                : icon_edit
                            }
                            alt="Редактировать"
                            className="edit_icon"
                            onClick={() => handleOpenModal(project)}
                            style={{ cursor: "pointer", paddingLeft: "10px" }}
                            onMouseEnter={() => {
                              setActiveIdEditIcon(project.id);
                            }}
                            onMouseLeave={() => {
                              setActiveIdEditIcon(0);
                            }}
                          />
                        </td>
                        {PracticeFields.filter(
                          (field) => field.name === "id"
                        ).map((field) =>
                          visibleFields.includes(field.name) ? (
                            <td
                              key={field.name}
                              onClick={() =>
                                handleCellClick(project.id!, field.name)
                              }
                              className={
                                expandedCells.has(`${project.id}_${field.name}`)
                                  ? "expanded"
                                  : ""
                              }
                            >
                              {project[field.name as keyof User]?.toString() ||
                                "—"}
                            </td>
                          ) : null
                        )}
                        {PracticeFields.map(
                          (field) =>
                            visibleFields.includes(field.name) &&
                            ["lastName"].includes(field.name) && (
                              <td
                                key={`${project.id}_${field.name}`}
                                onClick={() =>
                                  handleCellClick(project.id!, field.name)
                                }
                                className={`table-cell ${
                                  expandedCells.has(
                                    `${project.id}_${field.name}`
                                  )
                                    ? "expanded"
                                    : ""
                                }`}
                              >
                                {field.name === "lastName" && project.lastName
                                  ? `${project.lastName || ""} ${project.firstName || ""} ${project.middleName || ""}`
                                  : project[
                                      field.name as keyof User
                                    ]?.toString() || ""}
                              </td>
                            )
                        )}
                        {/* стажировки */}

                        {InternFields.filter(
                          (field) => field.name !== "practice_task"
                        ).map((field) =>
                          visibleFieldsIntern.includes(field.name) ||
                          field.name === "status" ? (
                            <td
                              key={field.name}
                              onClick={() =>
                                handleCellClick(project.id!, field.name)
                              }
                              className={
                                expandedCells.has(`${project.id}_${field.name}`)
                                  ? "expanded"
                                  : ""
                              }
                            >
                              {field.name === "status" ? (
                                project.internships &&
                                project.internships.length > 0 &&
                                project.internships[0].status ? (
                                  <span
                                    className="status-content"
                                    style={{
                                      backgroundColor:
                                        statusColors[
                                          project.internships[0]
                                            .status as keyof typeof statusColors
                                        ] || "#000",
                                    }}
                                  >
                                    {statusTranslations[
                                      project.internships[0]
                                        .status as keyof typeof statusTranslations
                                    ] || project.internships[0].status}
                                  </span>
                                ) : null
                              ) : field.name === "start_date" ||
                                field.name === "end_date" ? (
                                project.internships &&
                                project.internships.length > 0 &&
                                project.internships[0][field.name] ? (
                                  new Date(
                                    project.internships[0][field.name] as string
                                  ).toLocaleDateString()
                                ) : (
                                  ""
                                )
                              ) : (
                                project[field.name as keyof User]?.toString() ||
                                ""
                              )}
                            </td>
                          ) : null
                        )}
                        <td>
                          {internships
                            .filter(
                              (internship) => internship.user_id === project.id
                            )
                            .map((internship) => (
                              <div key={internship.id}>
                                {internship.practice_task || "—"}
                              </div>
                            ))}
                        </td>
                        {/* компотенции */}
                        {competenciesFields.map((field) =>
                          visibleFieldsCompetencies.includes(field.name) ? (
                            <td
                              key={field.name}
                              onClick={() =>
                                handleCellClick(project.id!, field.name)
                              }
                              className={
                                expandedCells.has(`${project.id}_${field.name}`)
                                  ? "expanded"
                                  : ""
                              }
                            >
                              {field.name === "text" &&
                              project.competencies &&
                              project.competencies.length > 0 ? (
                                <ul>
                                  {project.competencies.map((competency) => (
                                    <li key={competency.id}>
                                      {competency.text}
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                project[field.name as keyof User]?.toString() ||
                                ""
                              )}
                            </td>
                          ) : null
                        )}
                        {PracticeFields.map(
                          (field) =>
                            visibleFields.includes(field.name) &&
                            ![
                              "id",
                              "firstName",
                              "lastName",
                              "middleName",
                              "status",
                            ].includes(field.name) && (
                              <td
                                key={`${project.id}_${field.name}`}
                                onClick={() =>
                                  handleCellClick(project.id!, field.name)
                                }
                                className={`table-cell ${
                                  expandedCells.has(
                                    `${project.id}_${field.name}`
                                  )
                                    ? "expanded"
                                    : ""
                                }`}
                              >
                                {field.name === "start_date" ||
                                field.name === "end_date"
                                  ? typeof project[field.name as keyof User] ===
                                    "string"
                                    ? new Date(
                                        project[
                                          field.name as keyof User
                                        ] as string
                                      ).toLocaleDateString()
                                    : ""
                                  : project[
                                      field.name as keyof User
                                    ]?.toString() || ""}
                              </td>
                            )
                        )}
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
          )}
          <Pagination
            currentPage={currentPage}
            itemsPerPage={sumPage}
            totalItems={projects.length}
            paginate={paginate}
          />
        </>
      )}
    </div>
  );
};

export default TraineeTablePage;
